import {providerCheckUser} from "../providers/userProvider";
import {parseJwt} from "../../_app/utils/helpers";
import context from "../../_app/context";
import {makeVar} from "@apollo/client";

//@@@ type=boot

const userVar = makeVar(null);

function CheckUserCookieBoot()   {
    context.debugger("CheckUserCookieBoot");
    context.user = userVar;

    const jwt = providerCheckUser();
    const user = jwt ? parseJwt(jwt) : null;

    if(user)    {
        if(!context.headers)    {
            context.headers = {};
        }
        context.headers = {...context.headers, Authorization: `Bearer ${jwt}`};
        context.user(user);
    }

    return null
}
export default CheckUserCookieBoot;