import context from "../../../_app/context";
import {redirect} from "react-router-dom";


export const userLayoutLoader = async (params) => {
    const {route, user} = context;
    if(!user())  {
        return redirect(route("users.Login"));
    }
    return user();
}
