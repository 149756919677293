import {gql} from "@apollo/client";
import {PageDropDownFragment} from "./PageDropDown";
import {StorageFragment} from "./Storage";
import {CategoryFragment} from "./Category";
import {BrandFragment} from "./Brand";
import {CategoryFragmentAdmin} from "./Category";
import {BrandFragmentAdmin} from "./Brand";

export const PageFragment = gql`
	fragment PageFragment on Page {
		id
		menu
		parent_id
		group_id
		code
		page
		layout
		breadcrumbs
		active
		name
		slug
		seo_model
		seo_title
		seo_keywords
		seo_description
	}
`

export const PageAdminFragment = gql`
	fragment PageAdminFragment on Page {
		id
		menu
		parent_id
		group_id
		code
		prepend
		page
		layout
		breadcrumbs
		active
		name
		names
		slug
		slugs
		seo_model
		seo_title
		seo_titles
		seo_keywords
		seo_keywords_all
		seo_description
		seo_descriptions
	}
`

export const GetPages = gql`
	${PageFragment}
	${PageDropDownFragment}
	${StorageFragment}
	${CategoryFragment}
	${BrandFragment}
	query ($menu: Int, $active: Boolean, ) {
		GetPages (menu: $menu, active: $active, ) 
	{
			...PageFragment
			drop_down			{
				...PageDropDownFragment
				image				{
					...StorageFragment
				}
				category				{
					...CategoryFragment
				}
				brand				{
					...BrandFragment
				}
			}
		}
	}
`

export const GetPagesAdmin = gql`
	${PageAdminFragment}
	${PageDropDownFragment}
	${StorageFragment}
	${CategoryFragmentAdmin}
	${BrandFragmentAdmin}
	query ($search: String, ) {
		GetPagesAdmin (search: $search, ) 
	{
			...PageAdminFragment
			design_blocks_count

			drop_down			{
				...PageDropDownFragment
				image				{
					...StorageFragment
				}
				category				{
					...CategoryFragmentAdmin
				}
				brand				{
					...BrandFragmentAdmin
				}
			}
		}
	}
`

export const CreatePage = gql`
	${PageAdminFragment}
	mutation ($name: String!, $slug: String!, $code: String, $position: Int, $menu: Int, ) {
		CreatePage (name: $name, slug: $slug, code: $code, position: $position, menu: $menu, ) {
			...PageAdminFragment
		}
	}
`

export const UpdatePage = gql`
	${PageAdminFragment}
	mutation ($id: ID!, $name: String, $slug: String, $active: Boolean, $group_id: ID, $seo_title: String, $seo_keywords: String, $seo_description: String, ) {
		UpdatePage (id: $id, name: $name, slug: $slug, active: $active, group_id: $group_id, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, ) {
			...PageAdminFragment
		}
	}
`

export const DeletePage = gql`
	${PageAdminFragment}
	mutation ($id: ID!, ) {
		DeletePage (id: $id, ) {
			...PageAdminFragment
		}
	}
`

