import React, {useState} from "react";
import {CheckCircle, XCircle, X} from "react-feather";

//@@@ type=widget

function Alert({type="error", title, message, className=""})    {
    const [open, setOpen] = useState(true);

    if(!open) return null;

    return <div className={`border-2 relative shadow-md dark:text-black whitespace-pre-wrap rounded p-3  
    ${type === "success" ? "bg-green-50 border-green-300" : "bg-red-50 border-red-300"} ${className}`}>
        <div className="flex items-center">

            {type === "success"
                ? <CheckCircle size={36} className="text-green-600" />
                : <XCircle size={36} className="text-red-600" />}

            <div className="flex-grow ml-3">
                {title && <div className="font-bold">{title}</div>}
                <div>{message}</div>
            </div>
        </div>
        <button type="button" onClick={() => setOpen(false)}
                className="absolute top-1 right-1 rounded-full p-0 hover:bg-white">
            <X size={18} />
        </button>
    </div>
}
export default Alert;