import {gql} from "@apollo/client";

export const OrderPromoCodeFragment = gql`
	fragment OrderPromoCodeFragment on OrderPromoCode {
		id
		code
		count
		discount
		expires_at
		created_at
	}
`

export const GetOrderPromoCodesAdmin = gql`
	${OrderPromoCodeFragment}
	query ($search: String, ) {
		GetOrderPromoCodesAdmin (search: $search, ) 
	{
			data {
			...OrderPromoCodeFragment
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetOrderPromoCode = gql`
	${OrderPromoCodeFragment}
	query ($code: String!, ) {
		GetOrderPromoCode (code: $code, ) 
	{
			...OrderPromoCodeFragment
		}
	}
`

export const CreateOrderPromoCode = gql`
	${OrderPromoCodeFragment}
	mutation ($code: String!, $count: Int, $discount: Int, $expires_at: String, ) {
		CreateOrderPromoCode (code: $code, count: $count, discount: $discount, expires_at: $expires_at, ) {
			...OrderPromoCodeFragment
		}
	}
`

export const DeleteOrderPromoCode = gql`
	${OrderPromoCodeFragment}
	mutation ($id: ID!, ) {
		DeleteOrderPromoCode (id: $id, ) {
			...OrderPromoCodeFragment
		}
	}
`

