import {gql} from "@apollo/client";
import {OrderCountryDeliveryMethodFragment} from "./OrderCountryDeliveryMethod";
import {OrderCountryDeliveryMethodFragmentAdmin} from "./OrderCountryDeliveryMethod";

export const OrderCountryFragment = gql`
	fragment OrderCountryFragment on OrderCountry {
		id
		name
		payment_methods
	}
`

export const OrderCountryFragmentAdmin = gql`
	fragment OrderCountryFragmentAdmin on OrderCountry {
		id
		name
		names
		payment_methods
	}
`

export const GetOrdersCountries = gql`
	${OrderCountryFragment}
	${OrderCountryDeliveryMethodFragment}
	query  {
		GetOrdersCountries  
	{
			...OrderCountryFragment
			delivery_methods			{
				...OrderCountryDeliveryMethodFragment
			}
		}
	}
`

export const GetOrdersCountriesAdmin = gql`
	${OrderCountryFragmentAdmin}
	${OrderCountryDeliveryMethodFragmentAdmin}
	query  {
		GetOrdersCountriesAdmin  
	{
			...OrderCountryFragmentAdmin
			delivery_methods			{
				...OrderCountryDeliveryMethodFragmentAdmin
			}
		}
	}
`

export const CreateOrderCountry = gql`
	${OrderCountryFragmentAdmin}
	mutation ($name: String, $position: String, ) {
		CreateOrderCountry (name: $name, position: $position, ) {
			...OrderCountryFragmentAdmin
		}
	}
`

export const UpdateOrderCountry = gql`
	${OrderCountryFragmentAdmin}
	mutation ($id: ID!, $name: String, $payment_methods: [ID], ) {
		UpdateOrderCountry (id: $id, name: $name, payment_methods: $payment_methods, ) {
			...OrderCountryFragmentAdmin
		}
	}
`

export const DeleteOrderCountry = gql`
	${OrderCountryFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteOrderCountry (id: $id, ) {
			...OrderCountryFragmentAdmin
		}
	}
`

