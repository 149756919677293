import React from "react";
import {ApolloProvider} from "@apollo/client";
import {createUploadLink} from "apollo-upload-client";
import config from "../../config";
import {setContext} from "@apollo/client/link/context";
import {ApolloClient, from, InMemoryCache} from "@apollo/client";
import context from "../../_app/context";


function ApolloWrapper({children})   {
    context.debugger("ApolloWrapper");

    function uploadLink() {
        function customFetch(url, opts = {}) {  // LOAD PERCENTAGE CALCULATE (FOR STORAGE)
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest()

                xhr.open(opts.method || 'get', url)

                for (let k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k])

                xhr.onload = e =>
                    resolve({
                        ok: true,
                        text: () => Promise.resolve(e.target.responseText),
                        json: () => Promise.resolve(JSON.parse(e.target.responseText))
                    })

                xhr.onerror = reject

                if (xhr.upload)
                    xhr.upload.onprogress = opts.onProgress

                xhr.send(opts.body)
            });
        }
        return createUploadLink({
            uri: config.modules?._app?.graphQL,
            fetch: typeof window === 'undefined' ? global.fetch : customFetch
        });
    }
    function authLink() {
        return  setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    ...context.headers
                }
            }
        });
    }

    const client = new ApolloClient({
        cache: new InMemoryCache({

        }),
        link: from([authLink(), uploadLink()]),
    });

    context.apolloClient = client;

    return <ApolloProvider client={client}>
        {children}
    </ApolloProvider>
}
export default ApolloWrapper;