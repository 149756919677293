import {gql} from "@apollo/client";

export const DesignBlockBrandFragment = gql`
	fragment DesignBlockBrandFragment on DesignBlockBrand {
		id
		design_block_id
		brand_id
	}
`

export const DeleteDesignBlockBrand = gql`
	${DesignBlockBrandFragment}
	mutation ($id: ID!, ) {
		DeleteDesignBlockBrand (id: $id, ) {
			...DesignBlockBrandFragment
		}
	}
`

