import {gql} from "@apollo/client";

export const AdminFragment = gql`
	fragment AdminFragment on Admin {
		id
		authorization
		method
		name
		lastname
		username
		nickname
		email
		count_login_attempts
		last_login
	}
`

export const AdminSelfFragment = gql`
	fragment AdminSelfFragment on Admin {
		id
		authorization
		name
		lastname
		username
		nickname
		email
	}
`

export const AdminLoginFragment = gql`
	fragment AdminLoginFragment on Admin {
		error
		success
	}
`

export const GetAdministratorSelf = gql`
	${AdminSelfFragment}
	query  {
		GetAdministratorSelf  
	{
			...AdminSelfFragment
		}
	}
`

export const GetAdministrators = gql`
	${AdminFragment}
	query  {
		GetAdministrators  
	{
			...AdminFragment
		}
	}
`

export const GetAdministrator = gql`
	${AdminFragment}
	query ($id: ID!, ) {
		GetAdministrator (id: $id, ) 
	{
			...AdminFragment
		}
	}
`

export const LoginAdministrator = gql`
	${AdminLoginFragment}
	mutation ($email: String!, $password: String!, ) {
		LoginAdministrator (email: $email, password: $password, ) {
			...AdminLoginFragment
		}
	}
`

export const LogoutAdministrator = gql`
	${AdminLoginFragment}
	mutation ($id: ID, ) {
		LogoutAdministrator (id: $id, ) {
			...AdminLoginFragment
		}
	}
`

export const CreateAdministrator = gql`
	${AdminFragment}
	mutation ($name: String, $lastname: String, $username: String, $email: String!, $password: String!, ) {
		CreateAdministrator (name: $name, lastname: $lastname, username: $username, email: $email, password: $password, ) {
			...AdminFragment
		}
	}
`

export const UpdateAdministrator = gql`
	${AdminFragment}
	mutation ($id: ID!, $email: String, $name: String, $lastname: String, $username: String, ) {
		UpdateAdministrator (id: $id, email: $email, name: $name, lastname: $lastname, username: $username, ) {
			...AdminFragment
		}
	}
`

export const DeleteAdministrator = gql`
	${AdminFragment}
	mutation ($id: ID!, ) {
		DeleteAdministrator (id: $id, ) {
			...AdminFragment
		}
	}
`

