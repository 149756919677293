import {gql} from "@apollo/client";

export const CreateUserResetTokenFragment = gql`
	fragment CreateUserResetTokenFragment on UserReset {
		error
		success
	}
`

export const UserResetPasswordFragment = gql`
	fragment UserResetPasswordFragment on UserReset {
		error
		success
	}
`

export const UserResetTokenFragment = gql`
	fragment UserResetTokenFragment on UserReset {
		id
		email
		token
	}
`

export const CheckUserResetToken = gql`
	${UserResetTokenFragment}
	query ($token: String!, ) {
		CheckUserResetToken (token: $token, ) 
	{
			...UserResetTokenFragment
		}
	}
`

export const CreateUserResetToken = gql`
	${CreateUserResetTokenFragment}
	mutation ($email: String!, ) {
		CreateUserResetToken (email: $email, ) {
			...CreateUserResetTokenFragment
		}
	}
`

export const ResetUserPassword = gql`
	${UserResetPasswordFragment}
	mutation ($email: String!, $password: String!, $token: String!, ) {
		ResetUserPassword (email: $email, password: $password, token: $token, ) {
			...UserResetPasswordFragment
		}
	}
`

