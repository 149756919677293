import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {CategoryFragment} from "./Category";
import {BrandFragment} from "./Brand";
import {CategoryFragmentAdmin} from "./Category";
import {BrandFragmentAdmin} from "./Brand";
import {FilterFragment} from "./Filter";
import {FilterFragmentAdmin} from "./Filter";

export const ProductFragment = gql`
	fragment ProductFragment on Product {
		id
		code
		active
		state
		name
		code
		connected_id
		action
		new
		popular
		description
		long_description
		video
		view3d
		seo_title
		seo_keywords
		seo_description
		price
		discount_price
		process_price
		discount_price_start
		discount_price_end
	}
`

export const ProductFragmentAdmin = gql`
	fragment ProductFragmentAdmin on Product {
		id
		code
		active
		state
		name
		names
		code
		connected_id
		action
		new
		popular
		description
		descriptions
		long_description
		long_descriptions
		video
		view3d
		seo_title
		seo_titles
		seo_keywords
		seo_keywords_all
		seo_description
		seo_descriptions
		price
		prices
		discount_price
		discount_prices
		process_price
		discount_price_start
		discount_price_end
		error
		success
	}
`

export const GetProducts = gql`
	${ProductFragment}
	${StorageFragment}
	${CategoryFragment}
	${BrandFragment}
	query ($search: String, $page: Int, $first: Int, $order: String, $active: Boolean, $state: Boolean, $category_id: ID, $filter_id: ID, $filters: String, $brand_id: ID, $brands: [String], $group_id: ID, ) {
		GetProducts (search: $search, page: $page, first: $first, order: $order, active: $active, state: $state, category_id: $category_id, filter_id: $filter_id, filters: $filters, brand_id: $brand_id, brands: $brands, group_id: $group_id, ) 
	{
			data {
			...ProductFragment
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragment
			}
			brand			{
				...BrandFragment
			}
			connected			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetProductsAll = gql`
	${ProductFragment}
	${StorageFragment}
	${CategoryFragment}
	${BrandFragment}
	query ($ids: [ID], $orderByIds: [ID], ) {
		GetProductsAll (ids: $ids, orderByIds: $orderByIds, ) 
	{
			...ProductFragment
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragment
			}
			brand			{
				...BrandFragment
			}
			connected			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
		}
	}
`

export const GetProductsAdmin = gql`
	${ProductFragmentAdmin}
	${StorageFragment}
	${CategoryFragmentAdmin}
	${BrandFragmentAdmin}
	${ProductFragment}
	query ($page: Int, $first: Int, $search: String, $state: Boolean, $active: Boolean, $category_id: ID, $filter_id: ID, $brand_id: ID, $group_id: ID, $author_id: ID, ) {
		GetProductsAdmin (page: $page, first: $first, search: $search, state: $state, active: $active, category_id: $category_id, filter_id: $filter_id, brand_id: $brand_id, group_id: $group_id, author_id: $author_id, ) 
	{
			data {
			...ProductFragmentAdmin
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragmentAdmin
			}
			brand			{
				...BrandFragmentAdmin
			}
			connected			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetProduct = gql`
	${ProductFragment}
	${StorageFragment}
	${CategoryFragment}
	${FilterFragment}
	${BrandFragment}
	query ($id: ID!, ) {
		GetProduct (id: $id, ) 
	{
			...ProductFragment
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragment
			}
			filters			{
				...FilterFragment
			}
			brand			{
				...BrandFragment
			}
			images			{
				...StorageFragment
			}
			connected			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
			recommended			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
		}
	}
`

export const GetProductAdmin = gql`
	${ProductFragmentAdmin}
	${StorageFragment}
	${CategoryFragmentAdmin}
	${FilterFragmentAdmin}
	${BrandFragmentAdmin}
	${ProductFragment}
	query ($id: ID!, ) {
		GetProductAdmin (id: $id, ) 
	{
			...ProductFragmentAdmin
			image			{
				...StorageFragment
			}
			categories			{
				...CategoryFragmentAdmin
			}
			filters			{
				...FilterFragmentAdmin
			}
			brand			{
				...BrandFragmentAdmin
			}
			images			{
				...StorageFragment
			}
			connected			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
			recommended			{
				...ProductFragment
				image				{
					...StorageFragment
				}
			}
		}
	}
`

export const CreateProduct = gql`
	${ProductFragmentAdmin}
	mutation ($name: String!, $position: Int, ) {
		CreateProduct (name: $name, position: $position, ) {
			...ProductFragmentAdmin
		}
	}
`

export const UpdateProduct = gql`
	${ProductFragmentAdmin}
	mutation ($id: ID!, $name: String, $description: String, $active: Boolean, $state: Boolean, $action: Boolean, $new: Boolean, $popular: Boolean, $image_id: ID, $brand_id: ID, $position: Int, $code: String, $view3d: String, $video: String, $connected_id: String, $price: Float, $discount_price: Float, $discount_price_start: String, $discount_price_end: String, $seo_title: String, $seo_keywords: String, $seo_description: String, $attach_images: [ID], $detach_image: ID, $order_images: [ID], $attach_recommended: [ID], $detach_recommended: ID, $attach_specification: Boolean, $attach_documentations: [ID], ) {
		UpdateProduct (id: $id, name: $name, description: $description, active: $active, state: $state, action: $action, new: $new, popular: $popular, image_id: $image_id, brand_id: $brand_id, position: $position, code: $code, view3d: $view3d, video: $video, connected_id: $connected_id, price: $price, discount_price: $discount_price, discount_price_start: $discount_price_start, discount_price_end: $discount_price_end, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, attach_images: $attach_images, detach_image: $detach_image, order_images: $order_images, attach_recommended: $attach_recommended, detach_recommended: $detach_recommended, attach_specification: $attach_specification, attach_documentations: $attach_documentations, ) {
			...ProductFragmentAdmin
		}
	}
`

export const DeleteProduct = gql`
	${ProductFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteProduct (id: $id, ) {
			...ProductFragmentAdmin
		}
	}
`

export const UpdateDiscountPrices = gql`
	${ProductFragmentAdmin}
	mutation ($discount: Float!, $category_id: ID, $format: Int!, $discount_start: String, $discount_end: String, ) {
		UpdateDiscountPrices (discount: $discount, category_id: $category_id, format: $format, discount_start: $discount_start, discount_end: $discount_end, ) {
			...ProductFragmentAdmin
		}
	}
`

