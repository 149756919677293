import {useQuery} from "@apollo/client";
import {GetSettings} from "./Setting.js";

export function useGetSettings(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetSettings,
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetSettings']) ? data : previousData;
    const extracted = (holder) ? holder['GetSettings'] : [];

    if(error) console.log(error);

    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error
                ? <div className="bg-red-600 text-white px-4 py-2 mt-1 rounded-md">{error?.message}</div>
                : null
    }
}
export async function asyncGetSettings(variables = {}, apolloClient) {
    const {data, loading} = await apolloClient.query({
        query: GetSettings,
        variables
    });
    const extracted = data ? data['GetSettings'] : [];
    return {
        data: extracted,
        loading: loading,
        variables
    }
}
