import context from "../../../../_app/context";
import {redirect} from "react-router-dom";
import {asyncCheckUserResetToken} from "../../../../_graphql/CheckUserResetToken";

export const passwordResetLoader = async ({request, params}) => {
    const {apolloClient} = context;
    if(!params.token)   {
        return redirect(context.route("public.Error404"));
    }

    let resp = {};
    try {
        resp = await asyncCheckUserResetToken({token: params.token}, apolloClient);
    } catch (error) {
        return redirect(context.route("public.Error404"));
    }
    if(!resp.data)  {
        return redirect(context.route("public.Error404"));
    }
    return  resp.data;
}
