import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const BrandFragment = gql`
	fragment BrandFragment on Brand {
		id
		code
		image_id
		name
		description
		slug
		seo_title
		seo_keywords
		seo_description
	}
`

export const BrandFragmentAdmin = gql`
	fragment BrandFragmentAdmin on Brand {
		id
		code
		image_id
		name
		names
		description
		descriptions
		slug
		slugs
		seo_title
		seo_titles
		seo_keywords
		seo_keywords_all
		seo_description
		seo_descriptions
	}
`

export const GetBrand = gql`
	${BrandFragment}
	query ($id: ID!, ) {
		GetBrand (id: $id, ) 
	{
			...BrandFragment
		}
	}
`

export const GetBrandBySlug = gql`
	${BrandFragment}
	${StorageFragment}
	query ($slug: String!, ) {
		GetBrandBySlug (slug: $slug, ) 
	{
			...BrandFragment
			image			{
				...StorageFragment
			}
		}
	}
`

export const GetBrands = gql`
	${BrandFragment}
	${StorageFragment}
	query ($search: String, $first: Int, $page: Int, ) {
		GetBrands (search: $search, first: $first, page: $page, ) 
	{
			data {
			...BrandFragment
			image			{
				...StorageFragment
			}
			products_count

			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetBrandsAll = gql`
	${BrandFragment}
	${StorageFragment}
	query ($search: String, ) {
		GetBrandsAll (search: $search, ) 
	{
			...BrandFragment
			image			{
				...StorageFragment
			}
			products_count

		}
	}
`

export const GetShopBrands = gql`
	${BrandFragment}
	${StorageFragment}
	query ($category_id: ID, $group_id: ID, $slugs: [String], ) {
		GetShopBrands (category_id: $category_id, group_id: $group_id, slugs: $slugs, ) 
	{
			...BrandFragment
			image			{
				...StorageFragment
			}
			shop_products_count

		}
	}
`

export const GetBrandsAdmin = gql`
	${BrandFragmentAdmin}
	${StorageFragment}
	query ($search: String, $first: Int, $page: Int, ) {
		GetBrandsAdmin (search: $search, first: $first, page: $page, ) 
	{
			data {
			...BrandFragmentAdmin
			image			{
				...StorageFragment
			}
			products_count

			design_blocks_count

			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const CreateBrand = gql`
	${BrandFragmentAdmin}
	mutation ($name: String!, $slug: String!, $code: String, ) {
		CreateBrand (name: $name, slug: $slug, code: $code, ) {
			...BrandFragmentAdmin
		}
	}
`

export const UpdateBrand = gql`
	${BrandFragmentAdmin}
	mutation ($id: ID!, $name: String, $slug: String, $description: String, $seo_title: String, $seo_keywords: String, $seo_description: String, $image_id: ID, ) {
		UpdateBrand (id: $id, name: $name, slug: $slug, description: $description, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, image_id: $image_id, ) {
			...BrandFragmentAdmin
		}
	}
`

export const DeleteBrand = gql`
	${BrandFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteBrand (id: $id, ) {
			...BrandFragmentAdmin
		}
	}
`

