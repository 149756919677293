import {gql} from "@apollo/client";

export const ProductDocumentationFragment = gql`
	fragment ProductDocumentationFragment on ProductDocumentation {
		id
		name
	}
`

export const ProductDocumentationFragmentAdmin = gql`
	fragment ProductDocumentationFragmentAdmin on ProductDocumentation {
		id
		name
		names
	}
`

export const CreateProductDocumentation = gql`
	${ProductDocumentationFragmentAdmin}
	mutation ($file_id: String, $position: Int, ) {
		CreateProductDocumentation (file_id: $file_id, position: $position, ) {
			...ProductDocumentationFragmentAdmin
		}
	}
`

export const UpdateProductDocumentation = gql`
	${ProductDocumentationFragmentAdmin}
	mutation ($id: ID!, $name: String, $file_id: String, ) {
		UpdateProductDocumentation (id: $id, name: $name, file_id: $file_id, ) {
			...ProductDocumentationFragmentAdmin
		}
	}
`

export const DeleteProductDocumentation = gql`
	${ProductDocumentationFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteProductDocumentation (id: $id, ) {
			...ProductDocumentationFragmentAdmin
		}
	}
`

