import {useMemo} from "react";
import translations from "../../translations";
import context from "../../_app/context";
import config from "../../config";

//@@@ type=boot

function TransBoot() {
    context.debugger("TransBoot")

    const shorts = useMemo(() => {
        return config.modules.lang.languages.map(it => it.short);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.modules.lang.languages]);

    context.trans = (name) => {
        const lang = context.lang;
        if (typeof name === 'string' || name instanceof String) {
            if (!translations[name]) {
                console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${lang}`)
                return "*" + name;
            }
            if (!translations[name][shorts[lang]]) {
                return "*" + name;
            }
            return translations[name][shorts[lang]];
        }
        name = Object.values(name);
        if (!name[lang]) {
            console.log("MISSING: ", `Ne postoji trans za: "${name}" lang: ${lang}`)
            return "*" + name;
        }
        return name[lang];
    };
    return null;
}
export default TransBoot;