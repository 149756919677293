import {gql} from "@apollo/client";

export const OrderPaymentMethodFragment = gql`
	fragment OrderPaymentMethodFragment on OrderPaymentMethod {
		id
		name
		description
		individual
		legal
	}
`

export const OrderPaymentMethodFragmentAdmin = gql`
	fragment OrderPaymentMethodFragmentAdmin on OrderPaymentMethod {
		id
		name
		names
		description
		descriptions
		individual
		legal
	}
`

export const GetOrdersPaymentMethods = gql`
	${OrderPaymentMethodFragment}
	query  {
		GetOrdersPaymentMethods  
	{
			...OrderPaymentMethodFragment
		}
	}
`

export const GetOrdersPaymentMethodsAdmin = gql`
	${OrderPaymentMethodFragmentAdmin}
	query  {
		GetOrdersPaymentMethodsAdmin  
	{
			...OrderPaymentMethodFragmentAdmin
		}
	}
`

export const UpdateOrderPaymentMethod = gql`
	${OrderPaymentMethodFragmentAdmin}
	mutation ($id: ID!, $name: String, $description: String, $individual: Boolean, $legal: Boolean, ) {
		UpdateOrderPaymentMethod (id: $id, name: $name, description: $description, individual: $individual, legal: $legal, ) {
			...OrderPaymentMethodFragmentAdmin
		}
	}
`

