import {useEffect} from "react";
import context from "../../_app/context";
import {useGetUserSelf} from "../../_graphql/GetUserSelf";
import {providerLogoutUser} from "../providers/userProvider";

//@@@ type=register

function CheckUserRegister()   {
    context.debugger("CheckUserRegister");

    const {data, loading} = useGetUserSelf({
        skip: !context.user()
    });
    useEffect(() => {
        if(loading) return;
        if(context.user() && !data) {
            providerLogoutUser();
            return;
        }
        if(!data) return;

        const combinedUser = {...context.user(), ...data};
        context.user(combinedUser);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data]);

    return null
}
export default CheckUserRegister;