import context from "../../../_app/context";
import {redirect} from "react-router-dom";

export const cmsLayoutLoader = async (params) => {
    const {route, admin} = context;
    if(!admin())  {
        return redirect(route("cms.Login"));
    }
    return admin();
}
