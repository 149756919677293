import context from "../../_app/context";
import config from "../../config";

//@@@ type=register

function RouteRegister() {
    context.debugger("RouteRegister");

    context.route = (path, params) => {
        const pages = context.pages();
        const lang = context.lang;
        if(path === "index")    {
            return config.modules.lang.languages[lang].value;
        }
        if(!pages[path])       {
            console.log("MISSING: ", `Ne postoji route: ${path}`);
            return config.modules.lang.languages[lang].value;
        }
        let p = pages[path].full_path;
        if(p === null) return "";
        for(const key in params)    {
            p = p.replaceAll(`:${key}?`, params[key]).replaceAll(`:${key}`, params[key]);
        }
        if(p.includes("?")) {
            p = p.split("/").filter(it => !it.includes("?")).join("/");
        }
        return p;
    }
    return null
}
export default RouteRegister;