import context from "../../../_app/context";
import {asyncGetDesignBlocks} from "../../../_graphql/GetDesignBlocks";

export const blocksTemplateLoader = async ({request, params}) => {
    context.debugger("BlockTemplateLoader")
    const {currentPage, apolloClient} = context;

    if(!currentPage()?.code) return null;
    let resp = {};
    try {
        resp = await asyncGetDesignBlocks({code: currentPage().code}, apolloClient);
    } catch (error) {
        return null;
    }
    if(!resp.data)  {
        return null;
    }
    return resp.data;
}
export default blocksTemplateLoader;