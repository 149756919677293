import {gql} from "@apollo/client";
import {UserSessionFragment} from "./UserSession";

export const UserFragment = gql`
	fragment UserFragment on User {
		id
		method
		name
		lastname
		username
		nickname
		email
		phone
		zip
		city
		address
	}
`

export const UserFragmentAdmin = gql`
	fragment UserFragmentAdmin on User {
		id
		method
		banned
		name
		lastname
		username
		nickname
		email
		count_login_attempts
		created_at
		phone
		zip
		city
		address
	}
`

export const UserCreateFragment = gql`
	fragment UserCreateFragment on User {
		id
		name
		lastname
		username
		nickname
		email
		JWToken
		phone
		zip
		city
		address
	}
`

export const UserResponseFragment = gql`
	fragment UserResponseFragment on User {
		error
		success
	}
`

export const GetUserSelf = gql`
	${UserFragment}
	query  {
		GetUserSelf  
	{
			...UserFragment
		}
	}
`

export const GetUsersAdmin = gql`
	${UserFragmentAdmin}
	${UserSessionFragment}
	query ($page: Int, $first: Int, $search: String, $banned: Boolean, ) {
		GetUsersAdmin (page: $page, first: $first, search: $search, banned: $banned, ) 
	{
			data {
			...UserFragmentAdmin
			last_session			{
				...UserSessionFragment
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetUserAdmin = gql`
	${UserFragmentAdmin}
	query ($id: ID!, ) {
		GetUserAdmin (id: $id, ) 
	{
			...UserFragmentAdmin
		}
	}
`

export const LoginUser = gql`
	${UserResponseFragment}
	mutation ($email: String!, $password: String!, ) {
		LoginUser (email: $email, password: $password, ) {
			...UserResponseFragment
		}
	}
`

export const GoogleLoginUser = gql`
	${UserResponseFragment}
	mutation ($clientId: String!, $credential: String!, ) {
		GoogleLoginUser (clientId: $clientId, credential: $credential, ) {
			...UserResponseFragment
		}
	}
`

export const CreateUser = gql`
	${UserCreateFragment}
	mutation ($email: String!, $password: String!, ) {
		CreateUser (email: $email, password: $password, ) {
			...UserCreateFragment
		}
	}
`

export const LogoutUser = gql`
	${UserFragment}
	mutation ($id: ID, ) {
		LogoutUser (id: $id, ) {
			...UserFragment
		}
	}
`

export const UpdateUserAdmin = gql`
	${UserFragmentAdmin}
	mutation ($id: ID!, $banned: Boolean, ) {
		UpdateUserAdmin (id: $id, banned: $banned, ) {
			...UserFragmentAdmin
		}
	}
`

export const UpdateUser = gql`
	${UserFragment}
	mutation ($id: ID!, $username: String, $name: String, $lastname: String, $phone: String, $address: String, $zip: String, $city: String, ) {
		UpdateUser (id: $id, username: $username, name: $name, lastname: $lastname, phone: $phone, address: $address, zip: $zip, city: $city, ) {
			...UserFragment
		}
	}
`

export const DeleteUserAdmin = gql`
	${UserFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteUserAdmin (id: $id, ) {
			...UserFragmentAdmin
		}
	}
`

