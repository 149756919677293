import {gql} from "@apollo/client";

export const ProductGroupFragment = gql`
	fragment ProductGroupFragment on ProductGroup {
		id
		position
		name
		description
		filters
	}
`

export const GetProductsGroups = gql`
	${ProductGroupFragment}
	query ($search: String, ) {
		GetProductsGroups (search: $search, ) 
	{
			...ProductGroupFragment
			filters_with_names

			products_count

		}
	}
`

export const GetProductGroup = gql`
	${ProductGroupFragment}
	query ($id: ID!, ) {
		GetProductGroup (id: $id, ) 
	{
			...ProductGroupFragment
			filters_with_names

			products_count

		}
	}
`

export const CreateProductGroup = gql`
	${ProductGroupFragment}
	mutation ($name: String!, $description: String, $position: Int, ) {
		CreateProductGroup (name: $name, description: $description, position: $position, ) {
			...ProductGroupFragment
		}
	}
`

export const UpdateProductGroup = gql`
	${ProductGroupFragment}
	mutation ($id: ID!, $name: String, $description: String, $position: Int, $filters: String, ) {
		UpdateProductGroup (id: $id, name: $name, description: $description, position: $position, filters: $filters, ) {
			...ProductGroupFragment
		}
	}
`

export const DeleteProductGroup = gql`
	${ProductGroupFragment}
	mutation ($id: ID!, ) {
		DeleteProductGroup (id: $id, ) {
			...ProductGroupFragment
		}
	}
`

