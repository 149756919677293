import context from "../../../_app/context";
import {asyncGetProduct} from "../../../_graphql/GetProduct";


export const productLoader = async ({request, params}) => {
    const {apolloClient} = context;
    if(!params.id)   {
        return null;
    }

    let resp = {};
    try {
        resp = await asyncGetProduct({id: params.id}, apolloClient);
    } catch (error) {
        return null;
    }
    if(!resp.data)  {
        return null;
    }
    return resp.data;
}
