import {gql} from "@apollo/client";

export const PageErrorFragment = gql`
	fragment PageErrorFragment on PageError {
		id
	}
`

export const CreatePageError = gql`
	${PageErrorFragment}
	mutation ($content: String!, ) {
		CreatePageError (content: $content, ) {
			...PageErrorFragment
		}
	}
`

