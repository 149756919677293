import React from "react";
import Alert from "../widgets/Alert";
import context from "../../_app/context";
import {randString} from "../../_app/utils/helpers";
import {makeVar, useReactiveVar} from "@apollo/client";

//@@@ type=boot

const alertsVar = makeVar([]);

function AlertPanel()    {
    context.debugger("AlertPanel");
    const alerts = useReactiveVar(alertsVar);

    context.alert = (message) => {
        message.id = randString();
        alertsVar([...alertsVar(), message]);
        setTimeout(() => {
            const filtered = alertsVar().filter(item => item.id !== message.id);
            alertsVar(filtered);
        }, 6000);
    }

    return  <div className="fixed top-0 left-0 sm:left-auto right-0 w-full sm:w-[420px] z-50">
        <div className="ml-3 mt-3 mr-3">
            {alerts.map(item => {
                return <Alert key={item.id} {...item} className="mb-2" />
            })}
        </div>
    </div>
}
export default AlertPanel;