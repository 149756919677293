import {setCookie} from "../../_app/utils/helpers";
import config from "../../config";

const ADMIN_TOKEN = config?.modules?.cms?.tokenName;

export function providerLoginAdmin(token) {
    const expires = 60;
    setCookie(ADMIN_TOKEN, token, expires);
    return token;
}
export function providerCheckAdmin() {
    var name = ADMIN_TOKEN + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
export function providerLogoutAdmin() {
    setCookie(ADMIN_TOKEN, '', -1);
}
