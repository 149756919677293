import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const CategoryFragment = gql`
	fragment CategoryFragment on Category {
		id
		code
		parent_id
		size_chart_id
		name
		slug
		description
		seo_title
		seo_keywords
		seo_description
	}
`

export const CategoryFragmentAdmin = gql`
	fragment CategoryFragmentAdmin on Category {
		id
		code
		parent_id
		size_chart_id
		name
		names
		slug
		slugs
		description
		descriptions
		seo_title
		seo_titles
		seo_keywords
		seo_keywords_all
		seo_description
		seo_descriptions
	}
`

export const GetCategories = gql`
	${CategoryFragment}
	${StorageFragment}
	query ($search: String, $limit: Int, ) {
		GetCategories (search: $search, limit: $limit, ) 
	{
			...CategoryFragment
			size_chart			{
				...StorageFragment
			}
			products_count

		}
	}
`

export const GetShopCategories = gql`
	${CategoryFragment}
	query ($category_id: ID, $brand_id: ID, $group_id: ID, ) {
		GetShopCategories (category_id: $category_id, brand_id: $brand_id, group_id: $group_id, ) 
	{
			...CategoryFragment
			shop_products_count

		}
	}
`

export const GetCategoriesAdmin = gql`
	${CategoryFragmentAdmin}
	${StorageFragment}
	query ($search: String, ) {
		GetCategoriesAdmin (search: $search, ) 
	{
			...CategoryFragmentAdmin
			size_chart			{
				...StorageFragment
			}
			products_count

			design_blocks_count

		}
	}
`

export const CreateCategory = gql`
	${CategoryFragmentAdmin}
	mutation ($name: String!, $slug: String!, $position: Int, $code: String, $parent_id: ID, ) {
		CreateCategory (name: $name, slug: $slug, position: $position, code: $code, parent_id: $parent_id, ) {
			...CategoryFragmentAdmin
		}
	}
`

export const UpdateCategory = gql`
	${CategoryFragmentAdmin}
	mutation ($id: ID!, $name: String, $slug: String, $description: String, $seo_title: String, $seo_keywords: String, $seo_description: String, $image_id: String, $size_chart_id: ID, $attach_product: ID, $detach_product: ID, ) {
		UpdateCategory (id: $id, name: $name, slug: $slug, description: $description, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, image_id: $image_id, size_chart_id: $size_chart_id, attach_product: $attach_product, detach_product: $detach_product, ) {
			...CategoryFragmentAdmin
		}
	}
`

export const DeleteCategory = gql`
	${CategoryFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteCategory (id: $id, ) {
			...CategoryFragmentAdmin
		}
	}
`

