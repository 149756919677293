import {gql} from "@apollo/client";

export const OrderCommentFragment = gql`
	fragment OrderCommentFragment on OrderComment {
		id
		status
		message
		created_at
	}
`

export const CreateOrderComment = gql`
	${OrderCommentFragment}
	mutation ($order_id: ID!, $admin_id: Int = 1, $status: Int, $message: String, ) {
		CreateOrderComment (order_id: $order_id, admin_id: $admin_id, status: $status, message: $message, ) {
			...OrderCommentFragment
		}
	}
`

