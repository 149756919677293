import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";

export const FilterFragment = gql`
	fragment FilterFragment on Filter {
		id
		code
		parent_id
		position
		image_id
		name
		blocks
		for_order
	}
`

export const FilterFragmentAdmin = gql`
	fragment FilterFragmentAdmin on Filter {
		id
		code
		parent_id
		image_id
		name
		names
		position
		blocks
		for_order
	}
`

export const GetFilters = gql`
	${FilterFragment}
	${StorageFragment}
	query ($search: String, $parent_id: ID, $for_order: Boolean, $ids: [ID], ) {
		GetFilters (search: $search, parent_id: $parent_id, for_order: $for_order, ids: $ids, ) 
	{
			...FilterFragment
			image			{
				...StorageFragment
			}
			children			{
				...FilterFragment
				image				{
					...StorageFragment
				}
				products_count

			}
		}
	}
`

export const GetFiltersAdmin = gql`
	${FilterFragmentAdmin}
	${StorageFragment}
	query ($search: String, $parent_id: ID, ) {
		GetFiltersAdmin (search: $search, parent_id: $parent_id, ) 
	{
			...FilterFragmentAdmin
			image			{
				...StorageFragment
			}
			children			{
				...FilterFragmentAdmin
				image				{
					...StorageFragment
				}
				products_count

			}
		}
	}
`

export const GetFiltersWithParent = gql`
	${FilterFragment}
	${StorageFragment}
	query ($ids: [ID], $category_id: ID, $brand_id: ID, $brands: [String], ) {
		GetFiltersWithParent (ids: $ids, category_id: $category_id, brand_id: $brand_id, brands: $brands, ) 
	{
			...FilterFragment
			image			{
				...StorageFragment
			}
			products_count

			parent			{
				...FilterFragment
				image				{
					...StorageFragment
				}
			}
		}
	}
`

export const CreateFilter = gql`
	${FilterFragmentAdmin}
	mutation ($name: String!, $position: Int, $code: String, $parent_id: ID, ) {
		CreateFilter (name: $name, position: $position, code: $code, parent_id: $parent_id, ) {
			...FilterFragmentAdmin
		}
	}
`

export const UpdateFilter = gql`
	${FilterFragmentAdmin}
	mutation ($id: ID!, $name: String, $blocks: Boolean, $for_order: Boolean, $image_id: ID, $attach_product: ID, $detach_product: ID, ) {
		UpdateFilter (id: $id, name: $name, blocks: $blocks, for_order: $for_order, image_id: $image_id, attach_product: $attach_product, detach_product: $detach_product, ) {
			...FilterFragmentAdmin
		}
	}
`

export const DeleteFilter = gql`
	${FilterFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteFilter (id: $id, ) {
			...FilterFragmentAdmin
		}
	}
`

