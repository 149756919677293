import {providerCheckAdmin} from "../providers/adminProvider";
import {parseJwt} from "../../_app/utils/helpers";
import context from "../../_app/context";
import {makeVar} from "@apollo/client";

//@@@ type=boot

const adminVar = makeVar(null);

function CheckAdminCookieBoot()   {
    context.debugger("CheckAdminCookieBoot");
    context.admin = adminVar;

    const jwt = providerCheckAdmin();
    const admin = jwt ? parseJwt(jwt) : null;

    if(admin)    {
        if(!context.headers)    {
            context.headers = {};
        }
        context.headers = {...context.headers, AdminAuthorization: jwt};
        context.admin(admin);
    }

    return null
}
export default CheckAdminCookieBoot;