import {gql} from "@apollo/client";

export const UserSessionFragment = gql`
	fragment UserSessionFragment on UserSession {
		id
		suspicious
		city_name
		country_name
		location_time_zone
		subdivisions_name
		agent_browser
		agent_os
		agent_mobile
		updated_at
		created_at
	}
`

