import {gql} from "@apollo/client";

export const PageDropDownFragment = gql`
	fragment PageDropDownFragment on PageDropDown {
		id
		category_id
		brand_id
		image_id
		col
		title
		titles
	}
`

export const CreatePageDropDown = gql`
	${PageDropDownFragment}
	mutation ($page_code: String!, $category_id: ID, $brand_id: ID, $image_id: ID, $col: Int!, $position: String, $title: String, ) {
		CreatePageDropDown (page_code: $page_code, category_id: $category_id, brand_id: $brand_id, image_id: $image_id, col: $col, position: $position, title: $title, ) {
			...PageDropDownFragment
		}
	}
`

export const UpdatePageDropDown = gql`
	${PageDropDownFragment}
	mutation ($id: ID!, $title: String, $position: String, ) {
		UpdatePageDropDown (id: $id, title: $title, position: $position, ) {
			...PageDropDownFragment
		}
	}
`

export const DeletePageDropDown = gql`
	${PageDropDownFragment}
	mutation ($id: ID!, ) {
		DeletePageDropDown (id: $id, ) {
			...PageDropDownFragment
		}
	}
`

