import context from "../../_app/context";

//@@@ type=register

function MenuRegister() {
    context.debugger("MenuRegister");

    context.menu = (id) => {
        return Object.values(context.pages()).filter(item => {
            if(!item.active) return false;
            if(item.parent_id) return false;
            return parseInt(item.menu) === parseInt(id);
        });
    }

    return null
}
export default MenuRegister;