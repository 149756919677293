import context from "../../_app/context";

//@@@ type=register

function SettingRegister() {
    context.debugger("SettingRegister");

    context.setting = (name, full=false) => {
        if(!name) return "";
        if(!context.settings()[name]) return "";
        if(full)    {
            return context.settings()[name] || {};
        }
        return context.settings()[name]?.value || "";
    }

    return null
}
export default SettingRegister;