import {gql} from "@apollo/client";

export const DesignBlockTextFragment = gql`
	fragment DesignBlockTextFragment on DesignBlockText {
		id
		design_block_id
		text
	}
`

export const DesignBlockTextFragmentAdmin = gql`
	fragment DesignBlockTextFragmentAdmin on DesignBlockText {
		id
		design_block_id
		text
		texts
	}
`

export const UpsertDesignBlockText = gql`
	${DesignBlockTextFragmentAdmin}
	mutation ($id: ID, $design_block_id: ID, $text: String, ) {
		UpsertDesignBlockText (id: $id, design_block_id: $design_block_id, text: $text, ) {
			...DesignBlockTextFragmentAdmin
		}
	}
`

export const DeleteDesignBlockText = gql`
	${DesignBlockTextFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteDesignBlockText (id: $id, ) {
			...DesignBlockTextFragmentAdmin
		}
	}
`

