import {useEffect, useCallback} from "react";
import context from "../../_app/context";
import {makeVar} from "@apollo/client";
import {useLazyQuery} from "@apollo/client";
import {randString} from "../../_app/utils/helpers";
import {GetProductsAll} from "../../_graphql/Product";

//@@@ type=register

const BASKET_STORAGE_NAME = "basket";
const basketVar = makeVar([]);

const loadBasket = () =>  {
    let basket = localStorage.getItem(BASKET_STORAGE_NAME);
    if(!basket) return [];
    try {
        basket = JSON.parse(basket);
    } catch (e) {
        return [];
    }
    return basket;
}

function CheckBasketRegister()   {
    context.debugger("CheckBasketRegister");
    context.basket = basketVar;

    const [getProducts] = useLazyQuery(GetProductsAll);

    const syncProducts = useCallback(() => {
        const ids = loadBasket().map(it => it.productId);
        if(ids.length === 0) return;
        getProducts({variables: {ids}}).then(response => {
            const products = Object.values(response.data)[0];
            const holder = [];
            for(let i=0; i<loadBasket().length; i++)    {
                const item = loadBasket()[i];
                const find = products.find(it => parseInt(it.id) === item.productId);
                if(!find)   {
                    context.removeFromBasket(item.id);
                    continue;
                }
                item.product = find;
                holder.push(item);
            }
            context.basket(holder);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        syncProducts();
    }, [syncProducts]);

    context.addToBasket = ({id, qty, filters=[]}) => {
        if(!id) return false;
        const insert = {productId: parseInt(id), qty, filters, id: randString()};
        const newBasket = [...context.basket(), insert];
        localStorage.setItem(BASKET_STORAGE_NAME, JSON.stringify(newBasket));
        syncProducts();
    }
    context.removeFromBasket = (code) => {
        if(!code) return false;
        const newBasket = context.basket().filter(it => it.id !== code);
        context.basket(newBasket);
        localStorage.setItem(BASKET_STORAGE_NAME, JSON.stringify(newBasket));
    }
    context.emptyBasket = () => {
        const newBasket = [];
        context.basket(newBasket);
        localStorage.removeItem(BASKET_STORAGE_NAME);
    }
    context.totalBasket = () => {
        let totalBasket = 0;
        for(let i=0; i<context.basket().length; i++)    {
            totalBasket += context.basket()[i]?.product?.process_price * (context.basket()[i]?.qty || 1);
        }
        return totalBasket;
    }
    context.updateBasket = (id, field, value) => {
        const newBasket = context.basket().map(item => {
            if(item.id === id)   {
                return {...item, [field]: value};
            }
            return item;
        });
        context.basket(newBasket);
        localStorage.setItem(BASKET_STORAGE_NAME, JSON.stringify(newBasket));
    }

    return null
}
export default CheckBasketRegister;