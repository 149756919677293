
const APP_URL = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    ? "http://localhost:3000"
    : window.location.origin;

function server(path) {
    if (path.startsWith("/")) path = path.substring(1);
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `http://localhost:5011/${path}`;
    }
    return `${window.location.origin}/${path}`;
}
const config =  {
    modules: {
        _app: {
            color: '#171717',
            currency: ["RSD", "EUR"],
            server: server,
            appUrl: APP_URL,
            graphQL: server("graphql"),
        },
        lang: {
            languages: [
                {id: 0, short: 'sr', value: "/", name: "Srpski", label: "RS"},
                //{id: 1, short: 'en', value: "/home", name: "English", label: "EN"}
            ]
        },
        alerts: {},
        apollo: {
            //cache: ["GetDesignBlocks", "GetSettings", "GetCategories", "GetPages"],
        },
        cms: {
            tokenName: "adnmtokjwtfbb"
        },
        users: {
            tokenName: "ustrwokjwtfbb",
            google: {
                clientId: "739803745067-5ql5f5mvg3tskc5irca1j8iub5paqne0.apps.googleusercontent.com"
            }
        },
        pages: {
            dropDown: {
                colSpans: [1,1,1,1,1,1]
            },
            menus: [
                {id: 1, name: "Public"},
                {id: 2, name: "FAQ"},
                {id: 5, name: "Kupovina"},
                {id: 101, name: "Layouts"},
                {id: 102, name: "Protected"},
                {id: 103, name: "Users"},
                {id: 104, name: "CMS"},
                {id: 105, name: "SEO"},
            ]
        },
        settings: {
            image: true
        },
        storage: {
            root: "storage",
            folder: "babb",
            maxUpload: 8000000
        },
        public: {},
        categories: {
            image: false,
            sizeChart: true,
            maxDepth: 2,
            multiPick: false
        },
        filters: {
            image: true,
            maxDepth: 2,
        },
        banners: {
            placement: {
                shopmenu: {
                    name: "shop strana",
                    description: "shop strana desc",
                },
                /*productright: {
                    name: "proizvod strana",
                    description: "proizvod strana desc"
                },*/
            }
        },
        design: {
            blocks: {
                banners: {
                    type: "banners",
                    name: "baneri",
                    cmsElement: "banners.CmsDesignBlock",
                    element: "banners.DesignBlock",
                },
                cards: {
                    type: "cards",
                    name: "Card",
                    cmsElement: "design.CmsDesignBlockCards",
                    element: "design.DesignBlockCards",
                },
                products: {
                    type: "products",
                    cmsElement: "products.CmsDesignBlock",
                    element: "products.DesignBlock",
                    name: "proizvodi u carouselu"
                },
                categories: {
                    type: "categories",
                    name: "kategorije u carouselu",
                    cmsElement: "categories.CmsDesignBlock",
                    element: "categories.DesignBlock",
                },
                brands: {
                    type: "brands",
                    name: "brendovi u carouselu",
                    cmsElement: "brands.CmsDesignBlock",
                    element: "brands.DesignBlock",
                },
                editor: {
                    type: "editor",
                    name: "text editor",
                    cmsElement: "texteditor.CmsDesignBlock",
                    element: "texteditor.DesignBlock",
                },
            },
        },
        nestable: {},
        seo: {},
        products: {
            renderColumns: 4,
            statuses: [
                {value: "action", name: "akcija"},
                {value: "new", name: "novo"},
                {value: "popular", name: "popularno"},
            ],
            product: {
                code: true,
                barcode: false,
                price: true,
                connected: true,
                images: true,
                recommended: true,
                view3d: true
            }
        },
        texteditor: {},
        wishlist: {
            renderColumns: 5
        },
        brands: {},
        orders: {
            maxQty: 5,
            promoCodes: true,
            statuses: {
                1: {title: "u obradi", color: "#ff9e00", value: 1},
                2: {title: "primljeno", color: "#006fca", value: 2},
                3: {title: "poslato", color: "#009a56", value: 3},
                4: {title: "isporuceno", color: "#009a56", value: 4},
                5: {title: "otkazano", color: "#d00000", value: 5},
                6: {title: "reklamacija", color: "#D400B5FF", value: 6},
            }
        },
    },
}

export default config;