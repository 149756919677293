import {useEffect} from "react";
import context from "../../_app/context";
import {useLazyQuery} from "@apollo/client";
import {GetDesignBlocks} from "../../_graphql/DesignBlock";
import {pages} from "../../compiled";
import {fullUrl} from "../../_app/utils/link";

//@@@ type=register

function CheckPreloadDesignCode()   {
    context.debugger("CheckPreloadDesignCode");

    const [getDesignBlocks, {data}] = useLazyQuery(GetDesignBlocks);
    const designCode = document.documentElement.dataset.code;

    useEffect(() => {
        let Element = pages["design.BlocksTemplate"].lazy;
        Element.preload();

        if(designCode !== undefined)    {
            getDesignBlocks({
                variables: {
                    code: designCode
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!data)   return;
        const resp = Object.values(data)[0];
        if(resp[0] && resp[0].banners.length > 0 && resp[0].banners[0].mobile_image) {
            const src = fullUrl({image: resp[0].banners[0].mobile_image});
            if(src)  {
                const img=new Image();
                img.src=src;
            }
        }
    }, [data]);

    return null
}
export default CheckPreloadDesignCode;