import {setCookie} from "../../_app/utils/helpers";
import config from "../../config";

const USER_TOKEN = config?.modules?.users?.tokenName;

export function providerLoginUser(token) {
    const expires = 60;
    setCookie(USER_TOKEN, token, expires);
    return token;
}
export function providerCheckUser() {
    var name = USER_TOKEN + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}
export function providerLogoutUser() {
    setCookie(USER_TOKEN, '', -1);
}
export function getUserName(user)   {
    if(!user) return "";
    if(user.name) return user.name;
    return user.email;
}
