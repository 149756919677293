import {gql} from "@apollo/client";

export const OrderCountryDeliveryMethodFragment = gql`
	fragment OrderCountryDeliveryMethodFragment on OrderCountryDeliveryMethod {
		id
		name
		description
		delivery_price
		free_delivery_price_over
	}
`

export const OrderCountryDeliveryMethodFragmentAdmin = gql`
	fragment OrderCountryDeliveryMethodFragmentAdmin on OrderCountryDeliveryMethod {
		id
		name
		names
		description
		descriptions
		delivery_price
		delivery_prices
		free_delivery_price_over
		free_delivery_prices_over
	}
`

export const CreateOrderCountryDeliveryMethod = gql`
	${OrderCountryDeliveryMethodFragmentAdmin}
	mutation ($order_country_id: ID!, $name: String!, $position: String, ) {
		CreateOrderCountryDeliveryMethod (order_country_id: $order_country_id, name: $name, position: $position, ) {
			...OrderCountryDeliveryMethodFragmentAdmin
		}
	}
`

export const UpdateOrderCountryDeliveryMethod = gql`
	${OrderCountryDeliveryMethodFragmentAdmin}
	mutation ($id: ID!, $name: String, $delivery_price: Float, $free_delivery_price_over: Float, $description: String, ) {
		UpdateOrderCountryDeliveryMethod (id: $id, name: $name, delivery_price: $delivery_price, free_delivery_price_over: $free_delivery_price_over, description: $description, ) {
			...OrderCountryDeliveryMethodFragmentAdmin
		}
	}
`

export const DeleteOrderCountryDeliveryMethod = gql`
	${OrderCountryDeliveryMethodFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteOrderCountryDeliveryMethod (id: $id, ) {
			...OrderCountryDeliveryMethodFragmentAdmin
		}
	}
`

