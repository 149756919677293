import {gql} from "@apollo/client";

export const OrderNestableFragment = gql`
	fragment OrderNestableFragment on Nestable {
		error
		success
	}
`

export const OrderNestable = gql`
	${OrderNestableFragment}
	mutation ($items: String!, $table: String!, ) {
		OrderNestable (items: $items, table: $table, ) {
			...OrderNestableFragment
		}
	}
`

