import {gql} from "@apollo/client";

export const DesignBlockCategoryFragment = gql`
	fragment DesignBlockCategoryFragment on DesignBlockCategory {
		id
		design_block_id
		category_id
	}
`

export const DeleteDesignBlockCategory = gql`
	${DesignBlockCategoryFragment}
	mutation ($id: ID!, ) {
		DeleteDesignBlockCategory (id: $id, ) {
			...DesignBlockCategoryFragment
		}
	}
`

