import {gql} from "@apollo/client";
import {OrderProductFragment} from "./OrderProduct";
import {ProductFragment} from "./Product";
import {StorageFragment} from "./Storage";
import {OrderCommentFragment} from "./OrderComment";
import {AdminFragment} from "./Admin";

export const OrderFragment = gql`
	fragment OrderFragment on Order {
		id
		name
		lastname
		firm_name
		firm_person
		pib
		serial
		email
		phone
		address
		zip
		city
		message
		country
		seen
		code
		status
		full_price
		calculated_price
		currency
		payment_status
		payment_response
		payment_method
		payment_method_name
		delivery_method_name
		delivery_method_price
		free_delivery_method_price_over
		created_at
		promo_code
		promo_code_discount
	}
`

export const OrderFragmentUser = gql`
	fragment OrderFragmentUser on Order {
		id
		name
		lastname
		firm_name
		firm_person
		pib
		serial
		email
		phone
		address
		zip
		city
		message
		country
		code
		status
		full_price
		calculated_price
		currency
		payment_status
		payment_response
		payment_method
		payment_method_name
		delivery_method_name
		delivery_method_price
		free_delivery_method_price_over
		created_at
		promo_code
		promo_code_discount
	}
`

export const OrderResponseFragment = gql`
	fragment OrderResponseFragment on Order {
		success
		error
	}
`

export const GetOrdersUser = gql`
	${OrderFragmentUser}
	${OrderProductFragment}
	${ProductFragment}
	${StorageFragment}
	query ($search: String, $status: Int, ) {
		GetOrdersUser (search: $search, status: $status, ) 
	{
			data {
			...OrderFragmentUser
			orderProducts			{
				...OrderProductFragment
				product				{
					...ProductFragment
					image					{
						...StorageFragment
					}
				}
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetOrdersAdmin = gql`
	${OrderFragment}
	${OrderCommentFragment}
	${AdminFragment}
	${OrderProductFragment}
	${ProductFragment}
	${StorageFragment}
	query ($search: String, $seen: Boolean, $status: Int, $start_date: String, $end_date: String, $page: Int, $first: Int) {
		GetOrdersAdmin (search: $search, seen: $seen, status: $status, start_date: $start_date, end_date: $end_date, page: $page, first: $first) 
	{
			data {
			...OrderFragment
			comments			{
				...OrderCommentFragment
				admin				{
					...AdminFragment
				}
			}
			orderProducts			{
				...OrderProductFragment
				product				{
					...ProductFragment
					image					{
						...StorageFragment
					}
				}
			}
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const GetAllOrdersAdmin = gql`
	${OrderFragment}
	query ($search: String, $start_date: String!, $end_date: String!, $date_range: Boolean, ) {
		GetAllOrdersAdmin (search: $search, start_date: $start_date, end_date: $end_date, date_range: $date_range, ) 
	{
			...OrderFragment
		}
	}
`

export const GetOrder = gql`
	${OrderFragment}
	${OrderProductFragment}
	${ProductFragment}
	${StorageFragment}
	query ($code: String!, ) {
		GetOrder (code: $code, ) 
	{
			...OrderFragment
			orderProducts			{
				...OrderProductFragment
				product				{
					...ProductFragment
					image					{
						...StorageFragment
					}
				}
			}
		}
	}
`

export const GetOrderAdmin = gql`
	${OrderFragment}
	${OrderCommentFragment}
	${AdminFragment}
	${OrderProductFragment}
	${ProductFragment}
	${StorageFragment}
	query ($id: ID!, ) {
		GetOrderAdmin (id: $id, ) 
	{
			...OrderFragment
			comments			{
				...OrderCommentFragment
				admin				{
					...AdminFragment
				}
			}
			orderProducts			{
				...OrderProductFragment
				product				{
					...ProductFragment
					image					{
						...StorageFragment
					}
				}
			}
		}
	}
`

export const GetUnseenOrdersAdmin = gql`
	query ($seen: Boolean, ) {
		GetUnseenOrdersAdmin (seen: $seen, ) 
	}
`

export const CreateOrder = gql`
	${OrderResponseFragment}
	mutation ($tab: Int!, $countryId: ID!, $name: String, $lastname: String, $firm_name: String, $firm_person: String, $pib: String, $serial: String, $email: String!, $phone: String!, $address: String!, $zip: String!, $city: String!, $message: String, $basket: String!, $payment_method: ID, $delivery_method: ID, $promo_code: String, ) {
		CreateOrder (tab: $tab, countryId: $countryId, name: $name, lastname: $lastname, firm_name: $firm_name, firm_person: $firm_person, pib: $pib, serial: $serial, email: $email, phone: $phone, address: $address, zip: $zip, city: $city, message: $message, basket: $basket, payment_method: $payment_method, delivery_method: $delivery_method, promo_code: $promo_code, ) {
			...OrderResponseFragment
		}
	}
`

export const UpdateOrder = gql`
	${OrderFragment}
	mutation ($id: ID!, $status: Int, $seen: Boolean, ) {
		UpdateOrder (id: $id, status: $status, seen: $seen, ) {
			...OrderFragment
		}
	}
`

