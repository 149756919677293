import {gql} from "@apollo/client";

export const DesignBlockBannerFragment = gql`
	fragment DesignBlockBannerFragment on DesignBlockBanner {
		id
		design_block_id
		banner_id
	}
`

export const DeleteDesignBlockBanner = gql`
	${DesignBlockBannerFragment}
	mutation ($id: ID!, ) {
		DeleteDesignBlockBanner (id: $id, ) {
			...DesignBlockBannerFragment
		}
	}
`

