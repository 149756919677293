import mutations from "../../_graphql/mutations";
import validator from "../../_app/utils/validator";
import context from "../../_app/context";


export const submitAction = async ({request, params}) => {
    let formData = await request.formData();
    let mutationName = formData.get("mutation");
    const variables = {};
    const response = {errors: {}, success: null, error: null, values: {}, mutationName: mutationName};

    if(mutationName && mutations[mutationName] && mutations[mutationName].rules)   {
        const fields = mutations[mutationName].rules;

        for (const pair of formData.entries()) {
            const field = pair[0];
            const value = pair[1];
            variables[field] = value;
            if(fields === "validator")  continue;
            if(value === "")            continue;
            if(!fields[field])          continue;

            const errors = validator({
                rules: fields[field],
                value: value,
                trans: context.trans
            });

            response.values[field] = value;
            if(errors.length > 0) {
                response.errors[field] = errors;
            }
        }
    }
    if(Object.keys(response.errors).length > 0) {
        return response;
    }
    let errorMessage = null;
    try {
        const resp = await context.apolloClient.mutate({
            mutation: mutations[mutationName].mutation,
            variables
        });
        if(resp?.data)   {
            const result = resp.data[mutationName];
            if(result?.error)   {
                errorMessage = {message: result.error}
            } else if(result?.success)   {
                response.success = result.success;
            } else  {
                response.success = result;
            }
        }
    } catch (error) {
        if(error?.graphQLErrors?.length > 0)    {
            if(error?.graphQLErrors[0]?.debugMessage)    {
                errorMessage = {title: "Error", message: error?.graphQLErrors[0]?.debugMessage}
            }
            else if(error.graphQLErrors[0].extensions.validation)  {
                response.errors = error.graphQLErrors[0].extensions.validation;
            }
        } else {
            errorMessage = {title: "Error", message: error.message}
        }
    }

    if(errorMessage) {
        response.error = context.widget("alerts.Alert", errorMessage);
        context.alert(errorMessage);
    }
    return response;
}
