import {gql} from "@apollo/client";

export const StorageFragment = gql`
	fragment StorageFragment on Storage {
		id
		name
		mime
		width
		height
		folder_id
		bucket
	}
`

export const ResponseStorageFragment = gql`
	fragment ResponseStorageFragment on Storage {
		success
	}
`

export const GetStorage = gql`
	${StorageFragment}
	query ($page: Int, $first: Int, $search: String, $folder_id: ID, ) {
		GetStorage (page: $page, first: $first, search: $search, folder_id: $folder_id, ) 
	{
			data {
			...StorageFragment
			}
			paginatorInfo {
				currentPage
				lastPage
				count
				total
			}
		}
	}
`

export const TransferStorageFolder = gql`
	${ResponseStorageFragment}
	mutation ($ids: [ID], $transfer_id: ID, $folder_id: ID, ) {
		TransferStorageFolder (ids: $ids, transfer_id: $transfer_id, folder_id: $folder_id, ) {
			...ResponseStorageFragment
		}
	}
`

export const UploadStorageAdmin = gql`
	${ResponseStorageFragment}
	mutation ($file: Upload!, $name: String, $folder_id: ID, ) {
		UploadStorageAdmin (file: $file, name: $name, folder_id: $folder_id, ) {
			...ResponseStorageFragment
		}
	}
`

export const DeleteStorage = gql`
	${StorageFragment}
	mutation ($ids: [ID]!, ) {
		DeleteStorage (ids: $ids, ) {
			...StorageFragment
		}
	}
`

