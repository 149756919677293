import {useRouteError} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {CreatePageError} from "../../_graphql/PageError";
import {useEffect} from "react";
import context from "../../_app/context";

function PageErrorBoundary() {
    const {trans} = context;
    let error = useRouteError();
    const [createError] = useMutation(CreatePageError);

    useEffect(() => {
        createError({variables: {content: error.stack}});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className="container mx-auto text-center py-5">
        <div className="text-4xl font-bold mb-2">Ooops!</div>
        <div className="whitespace-pre-wrap">{trans("doslo je do greske na stranici")}</div>
    </div>
}
export default PageErrorBoundary;