import {gql} from "@apollo/client";

export const DesignBlockProductFragment = gql`
	fragment DesignBlockProductFragment on DesignBlockProduct {
		id
		design_block_id
		product_id
	}
`

export const DeleteDesignBlockProduct = gql`
	${DesignBlockProductFragment}
	mutation ($id: ID!, ) {
		DeleteDesignBlockProduct (id: $id, ) {
			...DesignBlockProductFragment
		}
	}
`

