import {useQuery} from "@apollo/client";
import {GetSettingsAdmin} from "./Setting.js";

export function useGetSettingsAdmin(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        GetSettingsAdmin,
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['GetSettingsAdmin']) ? data : previousData;
    const extracted = (holder) ? holder['GetSettingsAdmin'] : [];

    if(error) console.log(error);

    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error
                ? <div className="bg-red-600 text-white px-4 py-2 mt-1 rounded-md">{error?.message}</div>
                : null
    }
}
export async function asyncGetSettingsAdmin(variables = {}, apolloClient) {
    const {data, loading} = await apolloClient.query({
        query: GetSettingsAdmin,
        variables
    });
    const extracted = data ? data['GetSettingsAdmin'] : [];
    return {
        data: extracted,
        loading: loading,
        variables
    }
}
