import {useQuery} from "@apollo/client";
import {CheckAdminResetToken} from "./AdminReset.js";

export function useCheckAdminResetToken(params = {}) {
    const {data, previousData, loading, error, refetch, variables} = useQuery(
        CheckAdminResetToken,
        {...params, notifyOnNetworkStatusChange: true}
    );
    
    let holder = (data && data['CheckAdminResetToken']) ? data : previousData;
    const extracted = (holder) ? holder['CheckAdminResetToken'] : null;

    if(error) console.log(error);

    return {
        data: extracted, 
        loading: (loading && !previousData), 
        refetch, 
        variables,
        error: error
                ? <div className="bg-red-600 text-white px-4 py-2 mt-1 rounded-md">{error?.message}</div>
                : null
    }
}
export async function asyncCheckAdminResetToken(variables = {}, apolloClient) {
    const {data, loading} = await apolloClient.query({
        query: CheckAdminResetToken,
        variables
    });
    const extracted = data ? data['CheckAdminResetToken'] : null;
    return {
        data: extracted,
        loading: loading,
        variables
    }
}
