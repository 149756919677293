import config from "../../config";
import context from "../../_app/context";

//@@@ type=boot

function SetLangBoot()   {
    context.debugger("SetLangBoot");

    let lang = 0;

    const dataLang = document.documentElement.dataset.lang;
    if(dataLang !== undefined)   {
        const serverLang = parseInt(dataLang);
        if(Number.isInteger(serverLang) && serverLang < config.modules?.lang?.languages?.length)    {
            lang = serverLang;
        }
    }
    context.lang = lang;
    if(!context.headers)    {
        context.headers = {};
    }
    context.headers = {...context.headers, Lang: lang};

    return null;
}
export default SetLangBoot;