import {LoginAdministrator} from "./Admin";
import {LogoutAdministrator} from "./Admin";
import {CreateAdministrator} from "./Admin";
import {UpdateAdministrator} from "./Admin";
import {DeleteAdministrator} from "./Admin";
import {CreateAdminResetToken} from "./AdminReset";
import {ResetAdminPassword} from "./AdminReset";
import {LoginUser} from "./User";
import {GoogleLoginUser} from "./User";
import {CreateUser} from "./User";
import {LogoutUser} from "./User";
import {UpdateUserAdmin} from "./User";
import {UpdateUser} from "./User";
import {DeleteUserAdmin} from "./User";
import {CreateUserResetToken} from "./UserReset";
import {ResetUserPassword} from "./UserReset";
import {CreatePage} from "./Page";
import {UpdatePage} from "./Page";
import {DeletePage} from "./Page";
import {CreatePageDropDown} from "./PageDropDown";
import {UpdatePageDropDown} from "./PageDropDown";
import {DeletePageDropDown} from "./PageDropDown";
import {CreatePageError} from "./PageError";
import {UpdateSetting} from "./Setting";
import {TransferStorageFolder} from "./Storage";
import {UploadStorageAdmin} from "./Storage";
import {DeleteStorage} from "./Storage";
import {CreateStorageFolder} from "./StorageFolder";
import {DeleteStorageFolder} from "./StorageFolder";
import {ContactForm} from "./Form";
import {CreateCategory} from "./Category";
import {UpdateCategory} from "./Category";
import {DeleteCategory} from "./Category";
import {DeleteDesignBlockCategory} from "./DesignBlockCategory";
import {CreateFilter} from "./Filter";
import {UpdateFilter} from "./Filter";
import {DeleteFilter} from "./Filter";
import {CreateBanner} from "./Banner";
import {UpdateBanner} from "./Banner";
import {DeleteBanner} from "./Banner";
import {DeleteDesignBlockBanner} from "./DesignBlockBanner";
import {CreateDesignBlockCard} from "./DesignBlockCard";
import {UpdateDesignBlockCard} from "./DesignBlockCard";
import {DeleteDesignBlockCard} from "./DesignBlockCard";
import {CreateDesignBlock} from "./DesignBlock";
import {UpdateDesignBlock} from "./DesignBlock";
import {DeleteDesignBlock} from "./DesignBlock";
import {UpsertDesignBlockSetting} from "./DesignBlockSetting";
import {OrderNestable} from "./Nestable";
import {DeleteDesignBlockProduct} from "./DesignBlockProduct";
import {CreateProduct} from "./Product";
import {UpdateProduct} from "./Product";
import {DeleteProduct} from "./Product";
import {UpdateDiscountPrices} from "./Product";
import {CreateProductDocumentation} from "./ProductDocumentation";
import {UpdateProductDocumentation} from "./ProductDocumentation";
import {DeleteProductDocumentation} from "./ProductDocumentation";
import {CreateProductGroup} from "./ProductGroup";
import {UpdateProductGroup} from "./ProductGroup";
import {DeleteProductGroup} from "./ProductGroup";
import {UpdateProductSpecification} from "./ProductSpecification";
import {DeleteProductSpecification} from "./ProductSpecification";
import {UpsertDesignBlockText} from "./DesignBlockText";
import {DeleteDesignBlockText} from "./DesignBlockText";
import {CreateBrand} from "./Brand";
import {UpdateBrand} from "./Brand";
import {DeleteBrand} from "./Brand";
import {DeleteDesignBlockBrand} from "./DesignBlockBrand";
import {CreateOrderComment} from "./OrderComment";
import {CreateOrder} from "./Order";
import {UpdateOrder} from "./Order";
import {CreateOrderCountry} from "./OrderCountry";
import {UpdateOrderCountry} from "./OrderCountry";
import {DeleteOrderCountry} from "./OrderCountry";
import {CreateOrderCountryDeliveryMethod} from "./OrderCountryDeliveryMethod";
import {UpdateOrderCountryDeliveryMethod} from "./OrderCountryDeliveryMethod";
import {DeleteOrderCountryDeliveryMethod} from "./OrderCountryDeliveryMethod";
import {UpdateOrderPaymentMethod} from "./OrderPaymentMethod";
import {CreateOrderPromoCode} from "./OrderPromoCode";
import {DeleteOrderPromoCode} from "./OrderPromoCode";


const mutations = {
  "LoginAdministrator": {
    "mutation": LoginAdministrator,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "LogoutAdministrator": {
    "mutation": LogoutAdministrator,
    "rules": {}
  },
  "CreateAdministrator": {
    "mutation": CreateAdministrator,
    "rules": {
      "name": [
        "nullable",
        "max:190"
      ],
      "lastname": [
        "nullable",
        "max:190"
      ],
      "username": [
        "nullable",
        "max:190",
        "unique:admins"
      ],
      "email": [
        "email",
        "max:255",
        "required",
        "unique:admins"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "UpdateAdministrator": {
    "mutation": UpdateAdministrator,
    "rules": {
      "id": [
        "required"
      ],
      "email": [
        "email",
        "max:255",
        "min:5",
        "unique:admins"
      ],
      "name": [
        "nullable",
        "max:190"
      ],
      "lastname": [
        "nullable",
        "max:190"
      ],
      "username": [
        "nullable",
        "max:190",
        "unique:admins"
      ]
    }
  },
  "DeleteAdministrator": {
    "mutation": DeleteAdministrator,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateAdminResetToken": {
    "mutation": CreateAdminResetToken,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required",
        "exists:admins,email"
      ]
    }
  },
  "ResetAdminPassword": {
    "mutation": ResetAdminPassword,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ],
      "token": [
        "min:16",
        "max:255",
        "required"
      ]
    }
  },
  "LoginUser": {
    "mutation": LoginUser,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "GoogleLoginUser": {
    "mutation": GoogleLoginUser,
    "rules": {
      "clientId": [
        "required"
      ],
      "credential": [
        "required"
      ]
    }
  },
  "CreateUser": {
    "mutation": CreateUser,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required",
        "unique:users,email"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ]
    }
  },
  "LogoutUser": {
    "mutation": LogoutUser,
    "rules": {}
  },
  "UpdateUserAdmin": {
    "mutation": UpdateUserAdmin,
    "rules": {
      "id": [
        "required"
      ],
      "banned": [
        "nullable"
      ]
    }
  },
  "UpdateUser": {
    "mutation": UpdateUser,
    "rules": {
      "id": [
        "required"
      ],
      "username": [
        "max:190",
        "nullable"
      ],
      "name": [
        "max:190",
        "nullable"
      ],
      "lastname": [
        "max:190",
        "nullable"
      ],
      "phone": [
        "nullable"
      ],
      "address": [
        "nullable"
      ],
      "zip": [
        "nullable"
      ],
      "city": [
        "nullable"
      ]
    }
  },
  "DeleteUserAdmin": {
    "mutation": DeleteUserAdmin,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateUserResetToken": {
    "mutation": CreateUserResetToken,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required",
        "exists:users,email"
      ]
    }
  },
  "ResetUserPassword": {
    "mutation": ResetUserPassword,
    "rules": {
      "email": [
        "email",
        "max:255",
        "required"
      ],
      "password": [
        "min:6",
        "max:190",
        "required"
      ],
      "token": [
        "min:16",
        "max:255",
        "required"
      ]
    }
  },
  "CreatePage": {
    "mutation": CreatePage,
    "rules": {
      "name": [
        "max:191",
        "required"
      ],
      "slug": [
        "max:191",
        "slugify:name",
        "required"
      ],
      "menu": [
        "integer",
        "nullable"
      ]
    }
  },
  "UpdatePage": {
    "mutation": UpdatePage,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:191"
      ],
      "slug": [
        "max:191",
        "slugify:name"
      ],
      "active": [
        "nullable"
      ],
      "group_id": [
        "nullable",
        "exists:products_groups,id"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ]
    }
  },
  "DeletePage": {
    "mutation": DeletePage,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreatePageDropDown": {
    "mutation": CreatePageDropDown,
    "rules": {
      "page_code": [
        "required"
      ],
      "category_id": [
        "nullable"
      ],
      "brand_id": [
        "nullable"
      ],
      "image_id": [
        "nullable"
      ],
      "col": [
        "required"
      ],
      "title": [
        "max:191"
      ]
    }
  },
  "UpdatePageDropDown": {
    "mutation": UpdatePageDropDown,
    "rules": {
      "id": [
        "required"
      ],
      "title": [
        "max:191"
      ]
    }
  },
  "DeletePageDropDown": {
    "mutation": DeletePageDropDown,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreatePageError": {
    "mutation": CreatePageError,
    "rules": {
      "content": [
        "required"
      ]
    }
  },
  "UpdateSetting": {
    "mutation": UpdateSetting,
    "rules": {
      "id": [
        "required"
      ],
      "value": [
        "nullable"
      ],
      "image_id": [
        "nullable",
        "exists:storage,id"
      ]
    }
  },
  "TransferStorageFolder": {
    "mutation": TransferStorageFolder,
    "rules": {
      "folder_id": [
        "nullable"
      ]
    }
  },
  "UploadStorageAdmin": {
    "mutation": UploadStorageAdmin,
    "rules": {
      "file": [
        "required"
      ],
      "name": [
        "max:500"
      ],
      "folder_id": [
        "exists:storage_folders,id",
        "nullable"
      ]
    }
  },
  "DeleteStorage": {
    "mutation": DeleteStorage,
    "rules": {
      "ids": [
        "required"
      ]
    }
  },
  "CreateStorageFolder": {
    "mutation": CreateStorageFolder,
    "rules": {
      "name": [
        "max:190",
        "required"
      ]
    }
  },
  "DeleteStorageFolder": {
    "mutation": DeleteStorageFolder,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "ContactForm": {
    "mutation": ContactForm,
    "rules": {
      "name": [
        "required",
        "max:300"
      ],
      "phone": [
        "max:32"
      ],
      "recaptcha": [
        "required",
        "max:1000"
      ],
      "email": [
        "required",
        "email",
        "max:300"
      ],
      "subject": [
        "required",
        "max:1000"
      ],
      "message": [
        "required",
        "max:2000"
      ]
    }
  },
  "CreateCategory": {
    "mutation": CreateCategory,
    "rules": {
      "name": [
        "max:190",
        "required"
      ],
      "slug": [
        "max:190",
        "slugify:name",
        "unique:categories,slug",
        "required"
      ],
      "parent_id": [
        "nullable",
        "exists:categories,id"
      ]
    }
  },
  "UpdateCategory": {
    "mutation": UpdateCategory,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "slug": [
        "max:190",
        "slugify:name",
        "unique:categories,slug"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "size_chart_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteCategory": {
    "mutation": DeleteCategory,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockCategory": {
    "mutation": DeleteDesignBlockCategory,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateFilter": {
    "mutation": CreateFilter,
    "rules": {
      "name": [
        "max:190",
        "required"
      ],
      "parent_id": [
        "nullable",
        "exists:filters,id"
      ]
    }
  },
  "UpdateFilter": {
    "mutation": UpdateFilter,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteFilter": {
    "mutation": DeleteFilter,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateBanner": {
    "mutation": CreateBanner,
    "rules": {}
  },
  "UpdateBanner": {
    "mutation": UpdateBanner,
    "rules": {
      "id": [
        "required"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "mobile_image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "link": [
        "link",
        "nullable"
      ]
    }
  },
  "DeleteBanner": {
    "mutation": DeleteBanner,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockBanner": {
    "mutation": DeleteDesignBlockBanner,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateDesignBlockCard": {
    "mutation": CreateDesignBlockCard,
    "rules": {
      "design_block_id": [
        "required"
      ]
    }
  },
  "UpdateDesignBlockCard": {
    "mutation": UpdateDesignBlockCard,
    "rules": {
      "id": [
        "required"
      ],
      "title": [
        "nullable"
      ],
      "text": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteDesignBlockCard": {
    "mutation": DeleteDesignBlockCard,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateDesignBlock": {
    "mutation": CreateDesignBlock,
    "rules": {
      "code": [
        "max:190"
      ]
    }
  },
  "UpdateDesignBlock": {
    "mutation": UpdateDesignBlock,
    "rules": {
      "id": [
        "required"
      ],
      "title": [
        "nullable"
      ],
      "subtitle": [
        "nullable"
      ],
      "min_height": [
        "nullable"
      ],
      "background": [
        "nullable"
      ],
      "var_1": [
        "nullable"
      ],
      "var_2": [
        "nullable"
      ],
      "var_3": [
        "nullable"
      ],
      "var_4": [
        "nullable"
      ],
      "var_5": [
        "nullable"
      ]
    }
  },
  "DeleteDesignBlock": {
    "mutation": DeleteDesignBlock,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpsertDesignBlockSetting": {
    "mutation": UpsertDesignBlockSetting,
    "rules": {
      "value": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "OrderNestable": {
    "mutation": OrderNestable,
    "rules": {
      "items": [
        "required"
      ],
      "table": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockProduct": {
    "mutation": DeleteDesignBlockProduct,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateProduct": {
    "mutation": CreateProduct,
    "rules": {
      "name": [
        "max:190",
        "required"
      ]
    }
  },
  "UpdateProduct": {
    "mutation": UpdateProduct,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "description": [
        "nullable"
      ],
      "active": [
        "nullable"
      ],
      "state": [
        "nullable"
      ],
      "action": [
        "nullable"
      ],
      "new": [
        "nullable"
      ],
      "popular": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ],
      "brand_id": [
        "exists:brands,id",
        "nullable"
      ],
      "code": [
        "max:190",
        "nullable"
      ],
      "view3d": [
        "max:2000",
        "nullable"
      ],
      "video": [
        "max:255",
        "nullable"
      ],
      "connected_id": [
        "max:190",
        "nullable"
      ],
      "price": [
        "numeric",
        "nullable"
      ],
      "discount_price": [
        "numeric",
        "nullable"
      ],
      "discount_price_start": [
        "nullable"
      ],
      "discount_price_end": [
        "nullable"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ]
    }
  },
  "DeleteProduct": {
    "mutation": DeleteProduct,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpdateDiscountPrices": {
    "mutation": UpdateDiscountPrices,
    "rules": {
      "discount": [
        "required"
      ],
      "category_id": [
        "exists:categories,id"
      ],
      "format": [
        "required"
      ]
    }
  },
  "CreateProductDocumentation": {
    "mutation": CreateProductDocumentation,
    "rules": {
      "file_id": [
        "exists:storage,id"
      ]
    }
  },
  "UpdateProductDocumentation": {
    "mutation": UpdateProductDocumentation,
    "rules": {
      "id": [
        "required"
      ],
      "file_id": [
        "exists:storage,id"
      ]
    }
  },
  "DeleteProductDocumentation": {
    "mutation": DeleteProductDocumentation,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateProductGroup": {
    "mutation": CreateProductGroup,
    "rules": {
      "name": [
        "max:190",
        "required"
      ],
      "description": [
        "max:3000",
        "nullable"
      ]
    }
  },
  "UpdateProductGroup": {
    "mutation": UpdateProductGroup,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "description": [
        "max:3000",
        "nullable"
      ],
      "filters": [
        "nullable"
      ]
    }
  },
  "DeleteProductGroup": {
    "mutation": DeleteProductGroup,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpdateProductSpecification": {
    "mutation": UpdateProductSpecification,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteProductSpecification": {
    "mutation": DeleteProductSpecification,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpsertDesignBlockText": {
    "mutation": UpsertDesignBlockText,
    "rules": {
      "text": [
        "nullable"
      ]
    }
  },
  "DeleteDesignBlockText": {
    "mutation": DeleteDesignBlockText,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateBrand": {
    "mutation": CreateBrand,
    "rules": {
      "name": [
        "max:190",
        "required"
      ],
      "slug": [
        "max:190",
        "required",
        "slugify:name",
        "unique:brands,slug"
      ]
    }
  },
  "UpdateBrand": {
    "mutation": UpdateBrand,
    "rules": {
      "id": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "slug": [
        "max:190"
      ],
      "seo_title": [
        "nullable"
      ],
      "seo_keywords": [
        "nullable"
      ],
      "seo_description": [
        "nullable"
      ],
      "image_id": [
        "exists:storage,id",
        "nullable"
      ]
    }
  },
  "DeleteBrand": {
    "mutation": DeleteBrand,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteDesignBlockBrand": {
    "mutation": DeleteDesignBlockBrand,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateOrderComment": {
    "mutation": CreateOrderComment,
    "rules": {
      "order_id": [
        "exists:orders,id"
      ],
      "message": [
        "max:5000"
      ]
    }
  },
  "CreateOrder": {
    "mutation": CreateOrder,
    "rules": {
      "tab": [
        "required"
      ],
      "countryId": [
        "required"
      ],
      "name": [
        "max:190"
      ],
      "lastname": [
        "max:190"
      ],
      "firm_name": [
        "max:190"
      ],
      "firm_person": [
        "max:190"
      ],
      "pib": [
        "max:15"
      ],
      "serial": [
        "max:15"
      ],
      "email": [
        "max:190",
        "email",
        "required"
      ],
      "phone": [
        "max:32",
        "required"
      ],
      "address": [
        "max:190",
        "required"
      ],
      "zip": [
        "max:15",
        "required"
      ],
      "city": [
        "max:190",
        "required"
      ],
      "message": [
        "max:5000"
      ],
      "basket": [
        "required"
      ],
      "promo_code": [
        "max:190"
      ]
    }
  },
  "UpdateOrder": {
    "mutation": UpdateOrder,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateOrderCountry": {
    "mutation": CreateOrderCountry,
    "rules": {}
  },
  "UpdateOrderCountry": {
    "mutation": UpdateOrderCountry,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "DeleteOrderCountry": {
    "mutation": DeleteOrderCountry,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateOrderCountryDeliveryMethod": {
    "mutation": CreateOrderCountryDeliveryMethod,
    "rules": {
      "order_country_id": [
        "required"
      ],
      "name": [
        "required"
      ]
    }
  },
  "UpdateOrderCountryDeliveryMethod": {
    "mutation": UpdateOrderCountryDeliveryMethod,
    "rules": {
      "id": [
        "required"
      ],
      "delivery_price": [
        "numeric",
        "nullable"
      ],
      "free_delivery_price_over": [
        "numeric",
        "nullable"
      ],
      "description": [
        "nullable"
      ]
    }
  },
  "DeleteOrderCountryDeliveryMethod": {
    "mutation": DeleteOrderCountryDeliveryMethod,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "UpdateOrderPaymentMethod": {
    "mutation": UpdateOrderPaymentMethod,
    "rules": {
      "id": [
        "required"
      ]
    }
  },
  "CreateOrderPromoCode": {
    "mutation": CreateOrderPromoCode,
    "rules": {
      "code": [
        "max:190",
        "unique:orders_promo_codes,code",
        "required"
      ],
      "count": [
        "min:1"
      ],
      "discount": [
        "max:100",
        "min:1"
      ]
    }
  },
  "DeleteOrderPromoCode": {
    "mutation": DeleteOrderPromoCode,
    "rules": {
      "id": [
        "required"
      ]
    }
  }
}
export default mutations;
