import context from "../../_app/context";
import {makeVar} from "@apollo/client";

//@@@ type=register

const WISHLIST_STORAGE_NAME = "wishlist";
const wishlistVar = makeVar({});

const loadWishlist = () =>  {
    let wishlist = localStorage.getItem(WISHLIST_STORAGE_NAME);
    if(!wishlist) return [];
    try {
        wishlist = JSON.parse(wishlist);
    } catch (e) {
        return [];
    }
    return wishlist;
}

function CheckWishlistRegister()   {
    context.debugger("CheckWishlistRegister");
    context.wishlist = wishlistVar;
    context.wishlist(loadWishlist());

    context.toggleWishlist = (id) => {
        const wishlist = context.wishlist();
        id = parseInt(id);

        if(!id) return false;
        let newWishlist;
        if(wishlist.includes(id))   {
            newWishlist = wishlist.filter(item => item !== id);
        } else {
            newWishlist = [...wishlist, id];
        }
        context.wishlist(newWishlist);
        if(newWishlist.length > 0)      localStorage.setItem(WISHLIST_STORAGE_NAME, JSON.stringify(newWishlist));
        else                            localStorage.removeItem(WISHLIST_STORAGE_NAME);
    }

    return null
}
export default CheckWishlistRegister;