import {gql} from "@apollo/client";

export const DesignBlockCardFragment = gql`
	fragment DesignBlockCardFragment on DesignBlockCard {
		id
		title
		text
	}
`

export const DesignBlockCardFragmentAdmin = gql`
	fragment DesignBlockCardFragmentAdmin on DesignBlockCard {
		id
		position
		title
		titles
		text
		texts
	}
`

export const CreateDesignBlockCard = gql`
	${DesignBlockCardFragmentAdmin}
	mutation ($design_block_id: String!, $position: Int, ) {
		CreateDesignBlockCard (design_block_id: $design_block_id, position: $position, ) {
			...DesignBlockCardFragmentAdmin
		}
	}
`

export const UpdateDesignBlockCard = gql`
	${DesignBlockCardFragmentAdmin}
	mutation ($id: ID!, $title: String, $text: String, $image_id: String, ) {
		UpdateDesignBlockCard (id: $id, title: $title, text: $text, image_id: $image_id, ) {
			...DesignBlockCardFragmentAdmin
		}
	}
`

export const DeleteDesignBlockCard = gql`
	${DesignBlockCardFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteDesignBlockCard (id: $id, ) {
			...DesignBlockCardFragmentAdmin
		}
	}
`

