import {useEffect} from "react";
import context from "../../_app/context";
import {useGetAdministratorSelf} from "../../_graphql/GetAdministratorSelf";
import {providerLogoutAdmin} from "../providers/adminProvider";

//@@@ type=register

function CheckAdminRegister()   {
    context.debugger("CheckAdminRegister");

    const {data, loading} = useGetAdministratorSelf({
        skip: !context.admin()
    });

    useEffect(() => {
        if(loading) return;
        if(context.admin() && !data) {
            providerLogoutAdmin();
            window.location.reload();
            return;
        }
        if(!data) return;
        const combinedAdmin = {...context.admin(), ...data};
        context.admin(combinedAdmin);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, data]);

    return null
}
export default CheckAdminRegister;