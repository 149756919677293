import config from "../../config";

const ROOT = config?.modules?.storage?.root;
const FOLDER = config?.modules?.storage?.folder;

export function fullUrl({image, src, big, justImage=true}) {
    let imgSrc = "";
    if(src)  {
        imgSrc = src;
        if(src.startsWith("data:")) {
            if(src.startsWith("data:image"))   {}
            else if(src.startsWith("data:application/pdf"))   {
                imgSrc = config?.modules?._app?.server(`${ROOT}/${FOLDER}/000-pdf.png`);
            } else {
                imgSrc = config?.modules?._app?.server(`${ROOT}/${FOLDER}/000-file.png`);
            }
        }
    } else if(image)    {
        let imageName = `${image.bucket}/${image.name}`;
        if(image.mime?.startsWith("image")) {
            if(big)  {
                const imageArr = image.name.split(".");
                const mime = imageArr.pop();
                imageName = `${image.bucket}/${imageArr.join(".") + "-lg." + mime}`;
            }
        } else if(image.mime?.toLowerCase() === "application/pdf") {
            if(justImage) imageName = "000-pdf.png";
        } else  {
            if(justImage) imageName = "000-file.png";
        }
        imgSrc = config?.modules?._app?.server(`${ROOT}/${FOLDER}/${imageName}`);
    } else {
        imgSrc = config?.modules?._app?.server(`${ROOT}/${FOLDER}/000-no-image.png`);
    }
    return imgSrc;
}
export function relativeUrl(url) {
    if(url.startsWith(config.modules._app.appUrl))    {
        return url.replace(config.modules._app.appUrl, "");
    }
    return url;
}