import {gql} from "@apollo/client";

export const OrderProductFragment = gql`
	fragment OrderProductFragment on OrderProduct {
		id
		product_code
		product_name
		qty
		price
		discount_price
		process_price
		filters
	}
`

