import {gql} from "@apollo/client";

export const ProductSpecificationFragment = gql`
	fragment ProductSpecificationFragment on ProductSpecification {
		id
		position
		name
		value
	}
`

export const ProductSpecificationFragmentAdmin = gql`
	fragment ProductSpecificationFragmentAdmin on ProductSpecification {
		id
		position
		name
		names
		value
		values
	}
`

export const UpdateProductSpecification = gql`
	${ProductSpecificationFragmentAdmin}
	mutation ($id: ID!, $name: String, $value: String, ) {
		UpdateProductSpecification (id: $id, name: $name, value: $value, ) {
			...ProductSpecificationFragmentAdmin
		}
	}
`

export const DeleteProductSpecification = gql`
	${ProductSpecificationFragmentAdmin}
	mutation ($id: ID!, ) {
		DeleteProductSpecification (id: $id, ) {
			...ProductSpecificationFragmentAdmin
		}
	}
`

