import {gql} from "@apollo/client";

export const DesignBlockSettingFragment = gql`
	fragment DesignBlockSettingFragment on DesignBlockSetting {
		id
		code
		value
	}
`

export const DesignBlockSettingFragmentAdmin = gql`
	fragment DesignBlockSettingFragmentAdmin on DesignBlockSetting {
		id
		code
		value
		values
	}
`

export const UpsertDesignBlockSetting = gql`
	${DesignBlockSettingFragmentAdmin}
	mutation ($id: ID, $code: String, $design_block_id: String, $value: String, $image_id: String, ) {
		UpsertDesignBlockSetting (id: $id, code: $code, design_block_id: $design_block_id, value: $value, image_id: $image_id, ) {
			...DesignBlockSettingFragmentAdmin
		}
	}
`

