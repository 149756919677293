
const translations = {

    // AUTH
    "ulogujte se": {"sr": "Ulogujte se", "en": "Login"},
    "registrujte se": {"sr": "Registrujte se", "en": "Register"},
    "registrujte nalog": {"sr": "Registrujte nalog", "en": "Register account"},
    "zapamti me": {"sr": "Zapamti me", "en": "Remember me"},
    "unesite vas email": {"sr": "Unesite vaš email", "en": "Enter your email"},
    "posaljite email": {"sr": "Pošaljite email", "en": "Send email"},
    "lozinka": {"sr": "Lozinka", "en": "Password"},
    "nova lozinka": {"sr": "Nova Lozinka", "en": "New Password"},
    "resetujte lozinku": {"sr": "Resetujte lozinku", "en": "Reset password"},
    "zaboravili ste lozinku": {"sr": "Zaboravili ste lozinku?", "en": "Forgot your password?"},
    "izlogujte se": {"sr": "Izlogujte se", "en": "Logout"},
    "korisnicko ime": {"sr": "Korisničko ime", "en": "Username"},
    "ime": {"sr": "Ime", "en": "Name"},
    "naziv": {"sr": "Naziv", "en": "Name"},
    "prezime": {"sr": "Prezime", "en": "Lastname"},
    "ulica": {"sr": "Ulica", "en": "Street"},
    "mesto": {"sr": "Mesto", "en": "Place"},
    "posaljite": {"sr": "Pošaljite", "en": "Send"},
    "postanski broj": {"sr": "Poštanski broj", "en": "Zip Code"},
    'ne postoji stranica': {sr: 'Ne postoji stranica.', en: "Page doesn't exists."},

    // COOKIES
    "kolacici nisu odobreni za ovu uslugu": {
        sr: 'Kolačići nisu odobreni za ovu uslugu.',
        en: "Cookies not approved for this service."
    },
    "odobrite": {sr: "Odobrite", en: "Accept"},

    // validator
    "polje je obavezno": {"sr": "Polje je obavezno.", "en": "Field is required."},
    "polje nije validan email": {"sr": "Polje nije validan email.", "en": "Field is not valid email."},
    "polje mora biti min": {"sr": "Polje mora biti minimum: ", "en": "Field must be minimum: "},
    "polje mora biti max": {"sr": "Polje mora biti maksimalno: ", "en": "Field must be maximum: "},
    "karaktera": {"sr": " karaktera.", "en": " characters."},



    // GENERAL
    "idite na sajt": {"sr": "Idite na sajt", "en": "Go to website"},
    "sacuvajte": {"sr": "Sačuvajte", "en": "Save"},
    "currency": {"sr": "RSD", "en": "EUR"},
    "sacuvano": {"sr": "Sačuvano.", "en": "Saved."},
    "obrisite": {"sr": "Obrišite", "en": "Delete"},
    "obrisano": {"sr": "Obrisano.", "en": "Deleted."},
    "dodajte": {"sr": "Dodajte", "en": "Add"},
    "pozicija naslova": {"sr": "Pozicija naslova", "en": "Title position"},
    "pod": {"sr": "pod", "en": "sub"},
    "detaljnije": {"sr": "Detaljnije", "en": "More"},
    "odaberi": {"sr": "Odaberi", "en": "Select"},
    "zatvorite": {"sr": "Zatvorite", "en": "Close"},
    "naslovna": {"sr": "Naslovna", "en": "Home"},
    "nije postavljeno": {"sr": "Nije postavljeno.", "en": "Not set."},
    "zelite da obrisete": {"sr": "Želite da obrišete?", "en": "Do you want to delete?"},
    "jezik": {"sr": "Jezik", "en": "Language"},
    "mapa sajta": {"sr": "Mapa sajta", "en": "Site map"},
    "prikazi sve": {"sr": "Prikaži sve", "en": "Explore all"},
    "vazni linkovi": {"sr": "Važni linkovi", "en": "Quick links"},
    "auto prevod": {"sr": "Auto prevod", "en": "Auto translate"},

    "kontaktirajte nas": {"sr": "Kontaktirajte nas", "en": "Contact us"},
    "adresa": {"sr": "Adresa", "en": "Address"},
    "ime i prezime": {"sr": "Ime i prezime", "en": "Full name"},
    "predmet": {"sr": "Predmet", "en": "Subject"},
    "broj telefona": {"sr": "Broj telefona", "en": "Phone number"},
    "poruka": {"sr": "Poruka", "en": "Message"},

    // NESTABLE
    "prevucite da promenite raspored": {"sr": "Prevucite da promenite raspored.", "en": "Drag to change order."},
    "premestite": {"sr": "Premestite", "en": "Drag to move"},
    "raspored sacuvan": {"sr": "Raspored sačuvan.", "en": "Order saved."},
    "otvorite sve": {"sr": "Otvorite sve", "en": "Open all"},
    "zatvorite sve": {"sr": "Zatvorite sve", "en": "Close all"},
    "predmet nije aktiviran i nece se prikazivati": {
        "sr": "Predmet nije aktiviran i neće se prikazivati.",
        "en": "Item is not activated and will not be displayed."
    },
    "aktivirano": {"sr": "Aktivirano", "en": "Activated"},
    "deaktivirano": {"sr": "Deaktivirano", "en": "Deactivated"},
    "doslo je do greske na stranici": {"sr": "Došlo je do greske na stranici.", "en": "A page error has occurred."},
    "kliknite da promenite": {"sr": "Kliknite da promenite", "en": "Click to change"},

    // PAGES
    "stranica": {"sr": "Stranica", "en": "Page"},
    "stranice": {"sr": "Stranice", "en": "Pages"},
    "dodajte stranu": {"sr": "Dodajte stranu", "en": "Add page"},
    "padajuci meni": {"sr": "Padajući meni", "en": "Drop down"},
    "prethodna": {"sr": "Prethodna", "en": "Previous"},
    "sledeca": {"sr": "Sledeća", "en": "Next"},
    // ADMIN
    "administrator": {"sr": "Administrator", "en": "Administrator"},
    "dodajte administratora": {"sr": "Dodajte administratora", "en": "Add administrator"},
    "broj pogresnih logovanja": {"sr": "Broj pogrešnih logovanja.", "en": "Wrong attempts to login"},
    "uredjaji": {"sr": "Uredjaji", "en": "Devices"},
    "ulogovan": {"sr": "Ulogovan", "en": "Logged in"},
    "aktivan": {"sr": "Aktivan", "en": "Aktive"},
    // USERS
    "korisnik": {"sr": "Korisnik", "en": "User"},
    "banujte": {"sr": "Banujte", "en": "Ban"},
    "registrovan": {"sr": "Registrovan", "en": "Registered"},
    "nalog": {"sr": "Nalog", "en": "Account"},
    "uklonite ban": {"sr": "Uklonite ban", "en": "Unban"},
    "registrovan putem googla": {"sr": "Registrovan putem googla.", "en": "Registered by google."},
    "korisnik je banovan": {"sr": "Korisnik je banovan.", "en": "User is banned."},
    "zelite da banujete korisnika": {"sr": "Želite da banujete korisnika?", "en": "Want to ban user?"},
    "zelite da uklonite ban": {"sr": "Želite da uklonite ban?", "en": "Want to remove ban?"},
    // paginator
    "od": {"sr": "od", "en": "from"},
    "prazna pretraga": {"sr": "Prazna pretraga.", "en": "Empty search."},
    "prazno": {"sr": "Prazno.", "en": "Empty."},
    "sve": {"sr": "Sve", "en": "All"},
    "banovani": {"sr": "banovani", "en": "banned"},
    "pretraga": {"sr": "pretraga", "en": "search"},
    "status": {"sr": "status", "en": "status"},
    "stanje": {"sr": "stanje", "en": "state"},
    // STorage
    "folderi": {"sr": "Folderi", "en": "Folders"},
    "sliku": {"sr": "sliku", "en": "image"},
    "folder": {"sr": "folder", "en": "folder"},
    "dodajte folder": {"sr": "Dodajte folder", "en": "Add folder"},
    "fajlovi": {"sr": "Fajlovi", "en": "Files"},
    "uploadovano": {"sr": "Uploadovano.", "en": "Uploaded."},
    "prebacite fajlove u": {"sr": "Prebacite fajlove u", "en": "Trasfer files to"},
    "dodajte fajlove": {"sr": "Dodajte fajlove", "en": "Add files"},
    "max velicina fajla za upload": {"sr": "MAX veličina fajla za upload", "en": "MAX upload file size"},
    "selektujte sve": {"sr": "Selektujte sve", "en": "Select all"},
    "obrisite selektovano": {"sr": "Obrišite selektovano", "en": "Delete selected"},
    "zelite da prebacite fajlove": {"sr": "Želite da prebacite fajlove?", "en": "Want to transfer files?"},
    "prebacite u": {"sr": "Prebacite u", "en": "Transfer to"},
    "kliknite na fajl da promenite": {"sr": "Kliknite na fajl da promenite.", "en": "Click on file to change."},
    "kliknite na sliku da promenite": {
        "sr": "Kliknite na sliku da promenite",
        "en": "Click on the image to change"
    },
    "odaberite selektovano": {"sr": "Odaberite selektovano", "en": "Get selected"},
    "galerija": {"sr": "Galerija", "en": "Gallery"},
    // Proizvodi
    "proizvod": {"sr": "proizvod", "en": "product"},
    "kratak opis": {"sr": "Kratak opis", "en": "Short description"},
    "opis": {"sr": "Opis", "en": "Description"},
    "proizvodi": {"sr": "Proizvodi", "en": "Products"},
    "svi proizvodi": {"sr": "Svi proizvodi", "en": "All products"},
    "proizvodi u carouselu": {"sr": "Proizvodi u carousel-u", "en": "Products in carousel"},
    "kategorije u carouselu": {"sr": "Kategorije u carousel-u", "en": "Categories in carousel"},
    "brendovi u carouselu": {"sr": "Brendovi u carousel-u", "en": "Brands in carousel"},
    "proizvoda": {"sr": "proizvoda", "en": "products"},
    "proizvod je deaktiviran": {"sr": "Proizvod je deaktiviran.", "en": "Product is deactivated."},
    "na stanju": {"sr": "Na stanju", "en": "On state"},
    "nema na stanju": {"sr": "Nema na stanju", "en": "No state"},
    "dodajte proizvod": {"sr": "Dodajte proizvod", "en": "Add new product"},
    "prebacite na top": {"sr": "Prebacite na top", "en": "Move to top"},
    "sortiraj": {"sr": "Sortiraj", "en": "Sort"},
    "akcija": {"sr": "Akcija", "en": "Action"},
    "novo": {"sr": "Novo", "en": "New"},
    "popularno": {"sr": "Popularno", "en": "Popular"},
    "sifra": {"sr": "Šifra", "en": "Code"},
    "broj proizvoda": {"sr": "Broj proizvoda", "en": "Products count"},
    "povezani proizvodi": {"sr": "Povezani proizvodi (boja)", "en": "Connected products"},

    // CATEGORIES
    "kategoriju": {"sr": "kategoriju", "en": "category"},
    "kategorija": {"sr": "kategorija", "en": "category"},
    "kategorije": {"sr": "Kategorije", "en": "categories"},
    // Filter
    "filter": {"sr": "Filter", "en": "Filter"},
    "filteri": {"sr": "Filteri", "en": "Filters"},
    // DESIGN
    "blokovi": {"sr": "Blokovi", "en": "Blocks"},
    "naslov": {"sr": "Naslov", "en": "Title"},
    "podnaslov": {"sr": "Podnaslov", "en": "Subtitle"},
    "dodajte blok": {"sr": "Dodajte blok", "en": "Add block"},
    "blok nije poceo": {"sr": "Blok nije počeo", "en": "Block not started"},
    "blok je istekao": {"sr": "Blok je istekao", "en": "Block is expired"},
    "blok pocinje": {"sr": "Blok počinje", "en": "Block starts"},
    "blok se zavrsava": {"sr": "Blok se završava", "en": "Block ends"},
    "naslov u boji": {"sr": "Naslov u boji", "en": "Colored title"},
    "sirina celog ekrana": {"sr": "Širina celog ekrana", "en": "Full screen width"},
    "broj predmeta u jednom redu": {"sr": "Broj predmeta u jednom redu", "en": "Items in one row"},
    "naslov, text i slika": {"sr": "Naslov, text i slika", "en": "Card block"},
    "ime za link dugme": {"sr": "Ime za link dugme", "en": "Link button label"},
    "link": {"sr": "Link", "en": "Link"},
    "Card": {"sr": "Card (slika, naslov, text)", "en": "Card"},
    "postavite": {"sr": "Postavite", "en": "Set"},
    "preporuceni proizvodi": {"sr": "Preporučeni proizvodi", "en": "Recommended products"},
    "preporuka za kupovinu": {"sr": "Preporuka za kupovinu.", "en": "Recommendation for purchase."},
    "postavite popust u procentima": {"sr": "Postavite popust (broj - procenti).", "en": "Set discount (number - percentage)."},
    "discount desc": {"sr": "Postavite 0 da uklonite popuste.", "en": "Set to 0 to remove discounts."},
    "discount cat desc": {
        "sr": "Ako nije postavljena, popust se postavlja na ceo asortiman.",
        "en": "If not set, the discount is set for the entire assortment."},
    "formatiranje cene": {"sr": "Formatiranje cene", "en": "Price format"},
    "price format 1": {"sr": "Cena na 2 decimale (4583.3232 => 4500.32)", "en": "Price to 2 decimal places (4500.3232 => 4500.32)"},
    "price format 2": {"sr": "Zaokružite na pun broj (4583.3232 => 4584)", "en": "Round the whole number (4583.3232 => 4584)"},
    "price format 3": {"sr": "Zaokružite na najbližu deseticu (4583.3232 => 4590)", "en": "Round to the nearest tenth (4583.3232 => 4590)"},
    "price format 4": {"sr": "Zaokružite na najbližu deseticu minus 1 (4583.3232 => 4589)", "en": "Round to the nearest tenth minus 1 (4583.3232 => 4589)"},
    "price format 5": {"sr": "Zaokružite na najbližu stotinu (4583.3232 => 4600)", "en": "Round to the nearest hundred (4583.3232 => 4600)"},
    "price format 6": {"sr": "Zaokružite na najbližu stotinu minus 1 (4583.3232 => 4599)", "en": "Round to the nearest hundred minus 1 (4583.3232 => 4599)"},
    "pokrenite azuriranje": {"sr": "Pokrenite ažuriranje", "en": "Start update"},

    // banners
    "baner": {"sr": "baner", "en": "banner"},
    "baneri": {"sr": "Baneri", "en": "Banners"},
    "slika": {"sr": "Slika", "en": "Image"},
    "slika za mobilni": {"sr": "Slika za mobilni", "en": "Mobile image"},
    "prikazuj na mobilnom": {"sr": "Prikazuj na mobilnom", "en": "Show on mobile."},
    "prikazuj sliku kao popup": {"sr": "Prikazuj sliku kao POPUP", "en": "Prikazuj sliku kao POPUP"},
    "otvaraj u novom prozoru": {"sr": "Klikom otvaraj u novom prozoru.", "en": "On click open in new window."},
    "opis slike": {"sr": "Opis (alt) slike.", "en": "Image description (alt)."},
    "link slike": {"sr": "Link slike.", "en": "Image link."},
    "visina banera": {"sr": "Visina bannera", "en": "Banner height"},
    "centriraj banere": {"sr": "Centriraj banere", "en": "Banner position"},
    "gap": {"sr": "gap (razmak)", "en": "gap"},
    "shop strana": {"sr": "Shop strana", "en": "Shop page"},
    "proizvod strana": {"sr": "Proizvod strana", "en": "Product page"},
    "shop strana desc": {"sr": "Baneri ispod levog menija", "en": "Banners under left menu"},
    "proizvod desc": {"sr": "Baneri desno", "en": "Banners right"},
    "pozicija banera": {"sr": "Pozicija banera", "en": "Banner position"},

    // Text editor
    "text editor": {"sr": "Text editor", "en": "Text editor"},

    // SETTINGs
    "settings email info": {
        "sr": "više emailova odvojite zarezom: email1@gmail.com,email2@gmail.com...",
        "en": "separate multiple emails with a comma: email1@gmail.com,email2@gmail.com..."
    },
    "settings phone info": {
        "sr": "više telefona odvojite zarezom: 064 555 555,064 333 333...",
        "en": "separate multiple phones with a comma: 064 555 555,064 333 333..."
    },
    // BLOG
    "blog": {"sr": "vest", "en": "blog"},
    "kreirano": {"sr": "Kreirano", "en": "Created"},
    "poslednje vesti": {"sr": "Poslednje vesti", "en": "Latest news"},

    // COOKIES
    "cookies title": {"sr":"Ovaj veb sajt koristi kolačiće", "en":"This website uses cookies"},
    "cookies desc": {
        "sr": "Koristimo kolačiće za personalizaciju sadržaja, pružanje funkcija društvenih medija i analiziranje saobraćaja. Ako nastavite da koristite naše veb-stranice, saglasni ste sa korišćenjem naših kolačića.",
        "en": "We use cookies to personalize content, to provide functions social media and traffic analysis. If you continue to use our website, you agree to the use of our cookies."
    },
    "prihvatam": {"sr": "PRIHVATAM", "en": "ACCEPT"},


    // SEO
    "kreiraj novi sitemap svakih": {"sr": "Kreiraj novi sitemap svakih", "en": "Create new sitemap every"},
    "dana": {"sr": "dana", "en": "days"},
    // KONTAKT
    "podaci o firmi": {"sr": "Podaci o firmi", "en": "Company information"},
    "preuzmite": {"sr": "Preuzmite", "en": "Download"},
    "telefoni": {"sr": "Telefoni", "en": "Phones"},
    "kontakt": {"sr": "Kontakt", "en": "Contact"},
    "pratite nas": {"sr": "Pratite nas", "en": "Follow us"},
    "radno vreme": {"sr": "Radno vreme", "en": "Work hours"},

    // LISTA ZELJA
    "lista zelja": {"sr": "Lista želja", "en": "Wishlist"},
    "lista zelja je prazna": {"sr": "Lista želja je prazna.", "en": "Wishlist is empty."},
    "uklonite iz liste zelja": {"sr": "Uklonite iz liste želja", "en": "Remove from wishlist"},
    "dodajte u listu zelja": {"sr": "Dodajte u listu želja", "en": "Add to wishlist"},

    // product
    "cena": {"sr": "Cena", "en": "Price"},
    "popust": {"sr": "Popust", "en": "Discount"},
    "cena na popustu": {"sr": "Cena na popustu", "en": "Discount price"},
    "cena za prikaz": {"sr": "Cena za prikaz", "en": "Show price"},
    "popust pocinje": {"sr": "Popust počinje", "en": "Discount starts"},
    "popust se zavrsava": {"sr": "Popust se završava", "en": "Discount ends"},
    "dan skr": {"sr": "Dan", "en": "Day"},
    "sat skr": {"sr": "Sat", "en": "Hour"},
    "minut skr": {"sr": "Min", "en": "Min"},
    "sekund skr": {"sr": "Sek", "en": "Sec"},
    "specifikacije": {"sr": "Specifikacije", "en": "Specifications"},
    "dokumentacija": {"sr": "Dokumentacija", "en": "Documentations"},
    "unesite tekst": {"sr": "Unesite tekst", "en": "Insert text"},
    "dodajte novi red": {"sr": "Dodajte novi red", "en": "Add new row"},
    "fajl": {"sr": "fajl", "en": "file"},

    //BRANDS
    "brend": {"sr": "brend", "en": "brand"},
    "brendovi": {"sr": "Brendovi", "en": "Brands"},
    // GRUPE
    "dodajte grupu": {"sr": "Dodajte grupu", "en": "Add group"},
    "grupa": {"sr": "Grupa", "en": "Group"},
    "podelite": {"sr": "Podelite", "en": "Share"},

    // orders
    "email za porucivanje": {"sr": "Email za poručivanje", "en": "Orders email"},
    "email za porucivanje desc": {
        "sr": "Email na koji stizu obavestenja (vise emailova odvojite zarezom: email1@gmail.com,email2@gmail.com)",
        "en": "Email to which notifications arrive (separate multiple emails with a comma: email1@gmail.com,email2@gmail.com)"
    },
    "boja": {"sr": "Boja", "en": "Color"},
    "proizvod strana tekst za porucivanje": {"sr": "Proizvod strana - tekst za poručivanje", "en": "Product page - order text"},
    "tekst ispod dugmeta": {"sr": "tekst ispod dugmeta", "en": "text under button"},
    "pravno lice": {"sr": "Pravno lice", "en": "Legal"},
    "fizicko lice": {"sr": "Fizičko lice", "en": "Individual"},
    "nacin placanja": {"sr": "Način plaćanja", "en": "Payment method"},
    "nacin dostave": {"sr": "Način dostave", "en": "Delivery method"},
    "besplatna dostava preko": {"sr": "Besplatna dostava preko", "en": "Free delivery over"},
    "zemlja": {"sr": "Zemlja", "en": "Country"},
    "zemlju": {"sr": "zemlju", "en": "country"},
    "cena dostave": {"sr": "Cena dostave", "en": "Delivery price"},
    "broj porudzbina": {"sr": "Broj porudžbina", "en": "Orders count"},
    // basket
    "korpa": {"sr": "Korpa", "en": "Basket"},
    "ukupno": {"sr": "Ukupno", "en": "Total"},
    "uklonite": {"sr": "Uklonite", "en": "Remove"},
    "dodajte u korpu": {"sr": "Dodajte u korpu", "en": "Add to basket"},
    "korpa je prazna": {"sr": "Korpa je prazna", "en": "Basket is empty"},
    "dodato u korpu": {"sr": "Dodato u korpu", "en": "Added to basket"},
    "zavrsite porudzbinu": {"sr": "Završite porudžbinu", "en": "Finish order"},
    "porudzbina": {"sr": "Porudžbina", "en": "Order"},
    "porucivanje": {"sr": "Poručivanje", "en": "Ordering"},
    "for order desc": {
        "sr": "Prilikom poručivanja, kupac mora da odabere filter.",
        "en": "When ordering, the customer must select a filter."
    },
    "tabela velicina": {sr: "Tabela veličina", en: "Size chart"},
    "artikal": {"sr": "Artikal", "en": "Article"},
    "br": {"sr": "Br", "en": "No"},
    "kolicina": {"sr": "Količina", "en": "Quantity"},
    "informacije za porucivanje": {"sr": "Informacije za poručivanje", "en": "Informations"},
    "obavezna polja": {
        "sr": "Polja označena zvezdicom (*) su obavezna.",
        "en": "Fields marked with an asterisk (*) are required."
    },
    "maticni broj": {"sr": "Matični broj", "en": "Serial number"},
    "kontakt osoba": {"sr": "Kontakt osoba", "en": "Contact person"},
    "dodatni opis": {"sr": "Dodatni opis", "en": "Additional description"},
    "ime firme": {"sr": "Ime firme", "en": "Firm name"},
    "firma": {"sr": "Firma", "en": "Firm"},
    "telefon": {"sr": "Telefon", "en": "Phone"},
    "dodatna napomena": {"sr": "Dodatna napomena", "en": "Extra message"},
    "grad": {"sr": "Grad", "en": "City"},
    // ORDER
    "posalji porudzbinu": {"sr": "POŠALJI PORUDŽBINU", "en": "SUBMIT ORDER"},
    "procesuiram": {"sr": "Procesuiram...", "en": "Processing..."},
    "u obradi": {"sr": "U obradi", "en": "In process"},
    "primljeno": {"sr": "Primljeno", "en": "Received"},
    "isporuceno": {"sr": "Isporučeno", "en": "Delivered"},
    "otkazano": {"sr": "Otkazano", "en": "Cancelled"},
    "reklamacija": {"sr": "Reklamacija", "en": "Complaint"},
    "poslato": {"sr": "Poslato", "en": "Sent"},
    "promenite status": {"sr": "Promenite status", "en": "Change status"},
    "dodajte komentar": {"sr": "Dodajte komentar", "en": "Add comment"},
    "zelite da promenite status": {"sr": "Želite da promenite status?", "en": "Do you want to change status?"},
    "datum": {"sr": "Datum", "en": "Date"},
    "osnovno": {"sr": "Osnovno", "en": "Info"},
    "porucena cena": {"sr": "Poručena cena", "en": "Ordered price"},
    "dostava i placanje": {"sr": "Dostava i plaćanje", "en": "Delivery and payment"},
    "promo kod": {"sr": "Promo kod", "en": "Promo code"},
    "uspesno ste porucili": {"sr": "Uspešno ste poručili!", "en": "You have successfully ordered!"},
    "hvala na poverenju": {"sr": "Hvala na poverenju!", "en": "Thank you for your trust!"},
    // PROMO CODE
    "unesite promo kod": {"sr": "Unesite promo kod", "en": "Enter promo code"},
    "proverite kod": {"sr": "Proverite kod", "en": "Check code"},
    "kreirajte promo kod": {"sr": "Kreirajte promo kod", "en": "Create promo code"},
    "broj kodova": {"sr": "Broj kodova", "en": "Number of codes"},
    "koliko puta kod moze da se iskoristi": {"sr": "Koliko puta kod može da se iskoristi (default je 1)", "en": "How many times the code can be used (default is 1)"},
    "isteka koda": {"sr": "isteka koda. Ako je prazno, traje neograničeno.", "en": "code expiration. If empty, it lasts indefinitely."},
    "kod je istekao": {"sr": "Kod je istekao.", "en": "Code is expired."},
    "preostalo": {"sr": "Preostalo", "en": "Left"},
    "istice": {"sr": "Ističe", "en": "Expires"},
    "promo kod je validan": {"sr": "Promo kod je validan", "en": "Promo code is valid"},
    "promo kod nije validan": {"sr": "Promo kod nije validan.", "en": "Promo code is invalid."},

    //Autor
    "autora": {sr: "autora", en: "author"},
    "autori": {sr: "Autori", en: "Authors"},
}
export default translations;