import React, {Suspense, useMemo, useState} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import context from "./context";
import {useReactiveVar} from "@apollo/client";

function Router()   {
    context.debugger("Router");
    const [routes, setRoutes] = useState(null);
    const pages = useReactiveVar(context.pages);

    const router = useMemo(() => {
        if(!pages || Object.values(pages).length === 0) {
            return null;
        }
        if(routes) return routes;
        const val = createBrowserRouter(
            Object.values(pages).filter(it => !it.layout)
        )
        setRoutes(val);
        return val;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages]);

    if(!router)  {
        return <div />
    }

    return <Suspense fallback={<div/>}>
        <RouterProvider router={router} />
    </Suspense>
}
export default Router;